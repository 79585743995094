import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Link, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { Logo } from './Logo';
import { CashBadge, TokenBadge } from '../assets/Icons';
import { DEFAULT_USER_IMAGE } from '../api/APIConfig';
import { CustomPopover } from './CustomComponents';
import PricingModal from './PricingSection';
import { debounce } from 'lodash';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import { isJUCE } from '../context/JUCE';

const NavigationButtons = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBack = () => {
    navigate(-1);
  };

  const handleForward = () => {
    navigate(1);
  };

  return (
    <div className={`flex items-center pl-8 transition-opacity duration-200 ${isJUCE() ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
      <div className="flex items-center">
        <button
          onClick={handleBack}
          className="p-1.5 transition-all duration-200 hover:bg-white/5 text-text-primary/70 hover:text-text-primary rounded-l-md"
          aria-label="Go back"
        >
          <ArrowLeft size={20} />
        </button>
        <div className="w-px h-full bg-white/10" />
        <button
          onClick={handleForward}
          className="p-1.5 transition-all duration-200 hover:bg-white/5 text-text-primary/70 hover:text-text-primary rounded-r-md"
          aria-label="Go forward"
        >
          <ArrowRight size={20} />
        </button>
      </div>
    </div>
  );
};

const HeaderSearch = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [searchText, setSearchText] = useState(searchParams.get('q') || '');

  useEffect(() => {
    if (location.pathname === '/') {
      setSearchText(searchParams.get('q') || '');
    }
  }, [searchParams, location.pathname]);

  const handleSearch = useCallback(
    debounce((query) => {
      const newSearchParams = new URLSearchParams(
        location.pathname === '/' ? searchParams : new URLSearchParams()
      );
      
      if (query.trim()) {
        newSearchParams.set('q', query);
      } else {
        newSearchParams.delete('q');
      }
      
      const search = newSearchParams.toString();
      navigate({
        pathname: '/search',
        search: search ? `?${search}` : ''
      });
    }, 300),
    [navigate, location.pathname, searchParams]
  );

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch(searchText);
    }
  };

  return (
    <div className="relative w-full max-w-[24rem] mx-auto translate-x-16">
      <input
        type="text"
        placeholder="Search all sounds..."
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
        onKeyPress={handleKeyPress}
        className="w-full bg-bg-secondary/50 border border-text-primary/15 border-white/30 rounded-full h-9 pl-8 pr-3 text-xs text-text-primary/80 placeholder-text-primary/30 focus:outline-none focus:ring-1 focus:ring-text-primary/60"
      />
      <div className="absolute left-2.5 top-1/2 transform -translate-y-1/2">
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          className="h-3.5 w-3.5 text-text-primary/30" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" 
          />
        </svg>
      </div>
    </div>
  );
};

const Header = ({ onMenuToggle }) => {
  const navigate = useNavigate();
  const { user, logout } = useContext(AuthContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isPricingOpen, setIsPricingOpen] = useState(false);

  const handleLogout = () => {
    logout();
    setIsDropdownOpen(false);
  };

  const handleAccountNavigation = () => {
    navigate('/account');
  };

  const cashBalance = (Number(String(user.usd_balance || 0).replace(/,/g, '')) / 100)
    .toLocaleString('en-US', { style: 'currency', currency: 'USD' });

  return (
    <>
<header className="fixed top-0 left-0 right-0 bg-bg-primary/80 backdrop-blur-md z-30 border-b border-white border-opacity-10 h-20 transition-all duration-300 shadow-2xl">
          <div className="grid grid-cols-2 sm:grid-cols-[auto_1fr_auto] items-center w-full px-3 sm:px-6 h-full gap-4">
          <div className="flex items-center">
            <Link to="/">
              <Logo />
            </Link>
            <div className="hidden sm:block">
              <NavigationButtons />
            </div>
          </div>
          
          <div className="hidden sm:block flex justify-center w-full">
            <HeaderSearch />
          </div>
          
          <div className="justify-self-end flex items-center space-x-2 sm:space-x-4">

            {/* Mobile-optimized balance display */}
            <div className="flex flex-col items-end text-sm">
              <button 
                onClick={handleAccountNavigation}
                className="flex items-center hover:opacity-80 transition-opacity duration-200 cursor-pointer mb-0.5"
              >
                <span className="flex items-center">
                  {user.credits || 0}
                  <TokenBadge />
                </span>
              </button>
              <button 
                onClick={handleAccountNavigation}
                className="flex items-center hover:opacity-80 transition-opacity duration-200 cursor-pointer"
              >
                <span className="flex items-center">
                  {cashBalance}
                  <CashBadge />
                </span>
              </button>
            </div>

            {/* User menu and avatar */}
            <div className="flex items-center space-x-2 sm:space-x-4">
              <CustomPopover
                isOpen={isDropdownOpen}
                setIsOpen={setIsDropdownOpen}
                trigger={
                  <button
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    className="focus:outline-none"
                  >
                    <img 
                      src={user.avatar || DEFAULT_USER_IMAGE} 
                      alt="User Avatar" 
                      className="w-8 h-8 sm:w-10 sm:h-10 rounded-full object-cover"
                    />
                  </button>
                }
                content={
                  <div className="w-40 shadow-2xl border border-white/10 rounded-lg overflow-hidden">

                    <Link
                      to="/me"
                      onClick={() => setIsDropdownOpen(false)}
                      className="block w-full px-4 py-2 text-left hover:bg-gray-700 transition-colors duration-200"
                    >
                      Profile
                    </Link>
                    <Link
                      to="/account"
                      onClick={() => setIsDropdownOpen(false)}
                      className="block w-full px-4 py-2 text-left hover:bg-gray-700 transition-colors duration-200"
                    >
                      Account
                    </Link>
                    <button
                      onClick={handleLogout}
                      className="block w-full px-4 py-2 text-left hover:bg-gray-700 transition-colors duration-200"
                    >
                      Logout
                    </button>
                  </div>
                }
              />

{/* Upgrade button */}
{!user.active_subscriptions.some(sub => sub.toLowerCase().includes('soundbank')) && (
  <button
    onClick={() => setIsPricingOpen(true)}
    className="bg-gradient-to-r from-accent-start to-accent-end text-white font-semibold px-3 py-1.5 sm:px-5 sm:py-1.5 text-sm sm:text-base rounded-full hover:opacity-90 hidden sm:block"
  >
    Upgrade
  </button>
)}

              {/* Mobile menu toggle */}
              <button
                className="sm:hidden p-1.5"
                onClick={onMenuToggle}
                aria-label="Toggle menu"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="3" y1="12" x2="21" y2="12"></line>
                  <line x1="3" y1="6" x2="21" y2="6"></line>
                  <line x1="3" y1="18" x2="21" y2="18"></line>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </header>
      
      <PricingModal 
        isOpen={isPricingOpen}
        setIsOpen={setIsPricingOpen}
      />
    </>
  );
};

export default Header;