import React, { useState, useEffect, useContext, useCallback, useRef, memo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { SearchRow } from './SearchRow';
import { SoundList } from './SoundList';
import Spinner from '../../components/Spinner';
import { fetchSounds, fetchGenres, fetchInstruments, likeSound, unlikeSound, purchaseSound, getDownloadURL, fetchSoundById } from '../../api/APIManager';
import { AuthContext } from '../../context/AuthContext';
import { JUCESend } from '../../context/JUCE';
import FeaturedSoundsCarousel from './FeaturedSoundsCarousel';
import { useAudioNavigation } from '../../context/AudioNavigationContext';
import { useLocation, useSearchParams } from 'react-router-dom';

const Header = memo(({ title, IconComponent, totalResults }) => (
  <div className="flex justify-between items-center p-4">
    <div className="flex items-center space-x-2">
      {IconComponent && <IconComponent className="w-6 h-6" />}
      <h1 className="text-2xl font-bold text-text-primary">{title}</h1>
    </div>

  </div>
));

const SearchSection = memo(({onSearch, initialFilters, title }) => (
  <div className="p-4 sm:p-4">
    <SearchRow onSearch={onSearch} initialFilters={initialFilters} title={title} />
  </div>
));

const SoundBrowser = ({ 
  additionalFilters = {}, 
  showFeatured = false, 
  title, 
  icon: IconComponent,
  isSelectable = false,
  selectedSounds = [],
  onSoundSelect,
  customRowRenderer,
  initialSounds = null,
  enableSearchParams = false
}) => {
  const [searchParams] = useSearchParams();

  const [sounds, setSounds] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({ sort_by_likes: additionalFilters.sort_by_likes });
  const { setCurrentPlayingSound: setGlobalCurrentPlayingSound, setIsPlaying: setGlobalIsPlaying } = useOutletContext();
  

  // Initialize searchRowFilters with URL parameters on explore page, otherwise use defaults
  const [searchRowFilters, setSearchRowFilters] = useState(() => {
    const baseFilters = {
      key: [],
      soundType: [],
      instruments: [],
      genres: [],
      searchText: '',
      sortBy: [filters.sort_by_likes ? 
        { id: 'popular', name: 'Most Popular' } : 
        { id: 'recent', name: 'Most Recent' }
      ],
      sort_by_likes: filters.sort_by_likes
    };

    // Only process URL parameters on explore page
    if (!enableSearchParams) {
      return baseFilters;
    }

    const urlQuery = searchParams.get('q');
    return {
      ...baseFilters,
      searchText: urlQuery || ''
    };
  });

  const [isLoadingSounds, setIsLoadingSounds] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const { 
    currentPlayingSound, 
    setCurrentPlayingSound,
    isPlaying,
    setIsPlaying,
    setSoundsList,
    navigateToSound
  } = useAudioNavigation();
  const [totalResults, setTotalResults] = useState(0);
  
  const pageSize = 20;
  const soundsRef = useRef(sounds);
  const loadingRef = useRef(false);
  const searchTimeoutRef = useRef(null);

  useEffect(() => {
    soundsRef.current = sounds;
  }, [sounds]);

  useEffect(() => {
    setSoundsList(sounds);
  }, [sounds, setSoundsList]);

  const handlePlay = useCallback((sound) => {
    if (currentPlayingSound && currentPlayingSound.id === sound.id) {
      isPlaying ? JUCESend('pauseSound') : JUCESend('playSound', sound);
      setIsPlaying(!isPlaying);
      setGlobalIsPlaying(!isPlaying);
    } else {
      setGlobalCurrentPlayingSound(sound);
      setCurrentPlayingSound(sound);
      JUCESend('playSound', sound);
      setIsPlaying(true);
      setGlobalIsPlaying(true);
    }
  }, [currentPlayingSound, isPlaying, setCurrentPlayingSound, setGlobalCurrentPlayingSound, setGlobalIsPlaying, setIsPlaying]);

  const handleKeyDown = useCallback((event) => {
    if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
      return;
    }

    if (event.code === 'Space') {
      event.preventDefault();
      if (currentPlayingSound) {
        handlePlay(currentPlayingSound);
      }
      return;
    }

    if (!currentPlayingSound) return;

    var navigationDirection = '';
    if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
      navigationDirection = 'next';
    } else if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
      navigationDirection = 'previous';
    }

    const newSound = navigateToSound(navigationDirection);
    if (newSound) {
      handlePlay(newSound);
    }
  }, [currentPlayingSound, handlePlay, navigateToSound]);

    // Add these new handlers
    const handleSearchInputChange = (e) => {
      const value = e.target.value;
      handleSearch({
        ...searchRowFilters,
        searchText: value
      });
    };
  
    const handleSearchKeyPress = (event) => {
      if (event.key === 'Enter') {
        handleSearch(searchRowFilters);
      }
    };
  // Add the SearchIcon component
  const SearchIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 text-text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
    </svg>
  );  

  // Only process URL search parameter changes on explore page
  useEffect(() => {

    const urlQuery = searchParams.get('q');
    if (urlQuery !== undefined) {
      setSearchRowFilters(prev => ({
        ...prev,
        searchText: urlQuery || ''
      }));
      
      setFilters(prev => ({
        ...prev,
        query: urlQuery || ''
      }));

      if (!loadingRef.current) {
        setIsSearching(true);
        loadSounds(1, { ...filters, query: urlQuery || '' });
      }
    }
  }, [searchParams, enableSearchParams]);

  const handleSearch = useCallback((newFilters) => {
    const newSortByLikes = newFilters.sortBy?.[0]?.id === 'popular';
    
    setSearchRowFilters(prev => ({
      ...newFilters,
      sort_by_likes: newSortByLikes
    }));
    
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      const updatedFilters = {
        query: newFilters.searchText || '',
        sort_by_likes: newSortByLikes
      };
    
      if (newFilters.key?.length > 0) {
        updatedFilters.key = newFilters.key[0].name;
      }

      if (newFilters.genres?.length > 0) {
        updatedFilters.genre_ids = newFilters.genres.map(genre => genre.id).join(',');
      }
    
      if (newFilters.instruments?.length > 0) {
        updatedFilters.instrument_ids = newFilters.instruments.map(instrument => instrument.id).join(',');
      }

      if (newFilters.soundType?.length > 0) {
        updatedFilters.type_id = newFilters.soundType.map(type => type.id).join(',');
      }
    
      setFilters(updatedFilters);
      setIsSearching(true);
      loadSounds(1, updatedFilters);

      // Update URL only on explore page
      if (enableSearchParams) {
        const params = new URLSearchParams();
        if (newFilters.searchText) {
          params.set('q', newFilters.searchText);
        }
        window.history.replaceState(null, '', `${window.location.pathname}${params.toString() ? `?${params.toString()}` : ''}`);
      }
    }, 300);
  }, [enableSearchParams]);


  // Update the loadSounds function to handle the query parameter
  const loadSounds = useCallback(async (newPage, newFilters = filters) => {
    if (loadingRef.current) return;
    loadingRef.current = true;
    setIsLoadingSounds(true);
    
    try {
      if (initialSounds) {
        setSounds(initialSounds);
        setPage(1);
        setTotalResults(initialSounds.length);
        setTotalPages(1);
      } else {
        const response = await fetchSounds(newPage, pageSize, { 
          ...newFilters, 
          ...additionalFilters,
          sort_by_likes: newFilters.sort_by_likes,
          key: newFilters.key,
          genre_ids: newFilters.genre_ids,
          instrument_ids: newFilters.instrument_ids,
          type_id: newFilters.type_id,
          query: newFilters.query // Add this line
        });
        
        setSounds(response.items);
        setPage(newPage);
        setTotalResults(response.total);
        setTotalPages(Math.ceil(response.total / pageSize));
      }
    } catch (error) {
      console.error('Error fetching sounds:', error);
    } finally {
      loadingRef.current = false;
      setIsLoadingSounds(false);
      setIsSearching(false);
      setIsInitialLoad(false);
    }
  }, [additionalFilters, filters, initialSounds]);




  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      loadSounds(newPage, filters);
    }
  };

  const handleDirectPageInput = (e) => {
    if (e.key === 'Enter') {
      const pageNumber = parseInt(e.target.value);
      if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
        handlePageChange(pageNumber);
      }
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
    
    pageNumbers.push(1);
    
    let startPage = Math.max(2, page - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages - 1, startPage + maxVisiblePages - 1);
    
    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(2, endPage - maxVisiblePages + 2);
    }
    
    if (startPage > 2) {
      pageNumbers.push('...');
    }
    
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    
    if (endPage < totalPages - 1) {
      pageNumbers.push('...');
    }
    
    if (totalPages > 1) {
      pageNumbers.push(totalPages);
    }
    
    return pageNumbers;
  };

  const handleLike = async (soundId) => {
    const success = await likeSound(soundId);
    if (success) {
      setSounds(prevSounds => 
        prevSounds.map(sound => 
          sound.id === soundId ? { ...sound, is_liked: true } : sound
        )
      );
    }
  };

  const handleUnlike = async (soundId) => {
    const success = await unlikeSound(soundId);
    if (success) {
      setSounds(prevSounds => 
        prevSounds.map(sound => 
          sound.id === soundId ? { ...sound, is_liked: false } : sound
        )
      );
    }
  };

  const handlePurchase = async (soundId) => {
    const success = await purchaseSound(soundId);
    if (success) {
      setSounds(prevSounds => 
        prevSounds.map(sound => 
          sound.id === soundId ? { ...sound, is_purchased: true } : sound
        )
      );
    }
    return success;
  };

  const handleDownload = async (soundId) => {
    const downloadUrl = await getDownloadURL(soundId);
    if (downloadUrl) {
      const sound = await fetchSoundById(soundId);
      const fileName = sound.file_name.substring(sound.file_name.lastIndexOf('/') + 1);
      await downloadFile(downloadUrl, fileName);
    }
  };

  const downloadFile = async (url, filename = 'sound.wav') => {
    const response = await fetch(url);
    const blob = await response.blob();
    const blobURL = URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.href = blobURL;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    
    document.body.removeChild(link);
    URL.revokeObjectURL(blobURL);
  };

  return (
    <div className="flex flex-col">
<div
  className={`bg-[#121212] ${showFeatured ? 'h-[740px]' : 'h-[40px]'}`}
  style={{
    background: showFeatured
      ? 'radial-gradient(circle at center, rgba(161, 0, 255, .05) 20%, rgba(18, 18, 18, 1) 100%)'
      : '#121212'
  }}
>
        <Header 
          title={title} 
          IconComponent={IconComponent} 
          totalResults={totalResults} 
        />
        {showFeatured && <FeaturedSoundsCarousel onPlay={handlePlay} />}
      </div>
      
     {/* Updated Mobile Sticky Search */}
     <div className="sm:hidden sticky top-0 z-20 bg-[#121212] border-b border-text-primary/5">
        <div className="px-4 py-3">
          <div className="relative w-full">
            <input
              type="text"
              placeholder={`Search${title ? ` in ${title.toLowerCase()}` : ''}...`}
              className="w-full bg-transparent border border-text-primary/15 rounded-full h-10 pl-8 pr-3 text-xs text-text-primary placeholder-text-primary/70 focus:outline-none focus:ring-1 focus:ring-text-primary"
              onChange={handleSearchInputChange}
              onKeyPress={handleSearchKeyPress}
              value={searchRowFilters.searchText}
            />
            <div className="absolute left-2.5 top-1/2 transform -translate-y-1/2">
              <SearchIcon />
            </div>
          </div>
        </div>
      </div>

      <SearchSection 
        onSearch={handleSearch}
        initialFilters={searchRowFilters}
        title={title}
      />

      <div className="relative">
        {(isInitialLoad || isSearching || isLoadingSounds) && (
          <div className="absolute inset-0 bg-bg-primary/50 backdrop-blur-sm flex justify-center items-center z-10">
            <Spinner />
          </div>
        )}

        {isInitialLoad ? (
          <div className="flex justify-center items-center h-64">
            <Spinner />
          </div>
        ) : sounds.length > 0 ? (
          <>
            <div className="p-4 pt-4 sm:p-4 sm:pt-0">
              <SoundList 
                sounds={sounds} 
                onLike={handleLike}
                onUnlike={handleUnlike}
                onPurchase={handlePurchase}
                onPlay={handlePlay}
                onDownload={handleDownload}
                currentPlayingSound={currentPlayingSound}
                isPlaying={isPlaying}
                isSelectable={isSelectable}
                selectedSounds={selectedSounds}
                onSoundSelect={onSoundSelect}
                customRowRenderer={customRowRenderer}
              />
            </div>

          <div className="mt-4 flex flex-col sm:flex-row items-center justify-between gap-4 border-t border-text-primary/10 pt-4 px-4 sm:px-8 pb-4">
            <div className="flex items-center gap-2 text-sm">
              <span>Total: {totalResults} items</span>
              <span className="text-text-primary/50">|</span>
              <span>Page {page} of {totalPages}</span>
            </div>
            
            <div className="flex items-center gap-2 flex-wrap justify-center">
              <button
                onClick={() => handlePageChange(page - 1)}
                disabled={page === 1 || isLoadingSounds}
                className="px-3 py-1 rounded-lg border border-text-primary/15 disabled:opacity-50 hover:bg-accent-end/10"
              >
                Previous
              </button>
              
              <div className="flex items-center gap-1">
                {getPageNumbers().map((pageNum, index) => (
                  <React.Fragment key={index}>
                    {pageNum === '...' ? (
                      <span className="px-2">...</span>
                    ) : (
                      <button
                        onClick={() => handlePageChange(pageNum)}
                        disabled={isLoadingSounds}
                        className={`px-3 py-1 rounded-lg border ${
                          pageNum === page
                            ? 'bg-accent-end text-text-primary border-accent-end'
                            : 'border-text-primary/15 hover:bg-accent-end/10'
                        }`}
                      >
                        {pageNum}
                      </button>
                    )}
                  </React.Fragment>
                ))}
              </div>

              <button
                onClick={() => handlePageChange(page + 1)}
                disabled={page === totalPages || isLoadingSounds}
                className="px-3 py-1 rounded-lg border border-text-primary/15 disabled:opacity-50 hover:bg-accent-end/10"
              >
                Next
              </button>

              <input
                type="number"
                min="1"
                max={totalPages}
                className="w-16 px-2 py-1 rounded-lg border border-text-primary/15 bg-transparent text-center"
                placeholder={page.toString()}
                onKeyDown={handleDirectPageInput}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="flex items-center justify-center h-32 text-text-secondary">
          No results
        </div>
      )}
    </div>
    </div>
  );
};


export default SoundBrowser;