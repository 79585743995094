import axios from 'axios';
import { API_ENDPOINTS, APPLICATION_ID, BACKBLAZE_BUCKET_NAME } from './APIConfig';
import { v4 as uuidv4 } from 'uuid';
import { convertWavToMp3 } from '../Audio/AudioUtils';

// Initialize with a default function instead of null
let globalShowToast = (message, type = 'error') => {
    // Default behavior: log to console if toast isn't initialized
    if (type === 'error') {
        console.error(message);
    } else {
        console.log(message);
    }
};

// Function to initialize toast
export const initializeToast = (showToast) => {
    if (typeof showToast === 'function') {
        globalShowToast = showToast;
    }
};

// Error handler utility
const handleError = (error, customMessage = null) => {
    const errorMessage = error.response?.data?.error || error.response?.data || error.message || customMessage || error.error || 'An error occurred ';
    globalShowToast(errorMessage, 'error');
    console.error(errorMessage, error);
    throw error;
};

// Success handler utility
const handleSuccess = (message) => {
    // globalShowToast(message, 'success');
};


// USER
export const fetchUserMe = async () => {
  try {
    const response = await axios.get(API_ENDPOINTS.GET_USER_ME)

    return response.data;
  } catch (error) {
    handleError(error, `Failed to fetch user details`);
    return {};
  }
};

export const fetchUserById = async (userId) => {
  try {
    const response = await axios.get(API_ENDPOINTS.GET_USER(userId))
    return response.data;
  } catch (error) {
    handleError(error, `Failed to fetch user details`);
    return {};
  }
};

export const followUser = async (userId) => {
  try {
    const response = await axios.post(`${API_ENDPOINTS.USERS}/${userId}/follow`)
    handleSuccess('Successfully followed user');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to follow user');
  }
};

export const unfollowUser = async (userId) => {
  try {
    const response = await 
      axios.delete(`${API_ENDPOINTS.USERS}/${userId}/follow`)
    
    handleSuccess('Successfully unfollowed user');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to unfollow user');
  }
};

// SOUNDS
export const createSound = async (soundData, abortSignal = null) => {
  const uniqueFolder = uuidv4();
  const uniqueFileName = `${uniqueFolder}/${soundData.file.name}`;
  const fileNameWithoutExtension = soundData.file.name.replace(/\.[^/.]+$/, "");
  const audioPreviewFileName = `${uniqueFolder}/${fileNameWithoutExtension}_preview.mp3`;
  let imageFileName = soundData.image ? `${uniqueFolder}/${soundData.image.name}` : null;

  try {
    const uploadResult = await uploadFileToServer(
      soundData.file, 
      uniqueFileName, 
      audioPreviewFileName, 
      soundData.image, 
      imageFileName, 
      null, 
      null, 
      abortSignal
    );

    // If upload was cancelled, don't proceed with creating the sound
    if (abortSignal?.aborted) {
      const cancelError = new Error('Upload cancelled');
      cancelError.isCancelled = true;
      throw cancelError;
    }

    const jsonData = {
      application_id: APPLICATION_ID,
      name: soundData.name,
      image: uploadResult.image_file_url || "",
      file_name: uniqueFileName,
      bucket_name: BACKBLAZE_BUCKET_NAME,
      file: "",
      tags: soundData.tags,
      genres: soundData.genres,
      instruments: soundData.instruments,
      audio_preview: uploadResult.preview_file_url,
      type: { id: soundData.typeId },
      key: soundData.key,
      file_backup: "",
      description: soundData.description,
      bpm: soundData.bpm,
      cost_in_credits: soundData.costInCredits,
      license: { id: soundData.license },
      file_size: soundData.file.size.toString(),
      sound_packs: soundData.sound_packs
    };

    if (soundData.status) {
      jsonData.status = soundData.status;
    }
    if (jsonData.key && jsonData.key.trim() === '') {
      jsonData.key = null;
    }

    const response = await axios.post(API_ENDPOINTS.SOUNDS, jsonData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
      },
      signal: abortSignal
    });

    handleSuccess('Sound created successfully');
    return { ...response.data, fileSize: soundData.file.size };
  } catch (error) {
    if (axios.isCancel(error) || error.isCancelled) {
      const cancelError = new Error('Upload cancelled');
      cancelError.isCancelled = true;
      throw cancelError;
    }
    handleError(error, 'Failed to create sound');
  }
};

export const updateSound = async (soundId, newSoundData) => {
  try {
    const originalSound = await fetchSoundById(soundId);
    const updatedSound = { ...originalSound };

    // Update basic fields
    const fields = ['name', 'description', 'key', 'bpm', 'costInCredits', 'cost_in_credits', 'status', 'featured'];
    fields.forEach(field => {
      if (newSoundData[field] !== undefined) {
        updatedSound[field] = newSoundData[field];
      }
    });

    // Update related fields
    if (newSoundData.type_id !== undefined) updatedSound.type = { id: newSoundData.type_id };
    if (newSoundData.license !== undefined) updatedSound.license = { id: newSoundData.license };
    if (newSoundData.sound_packs !== undefined) {
      updatedSound.sound_packs = newSoundData.sound_packs;
    }

    // Update arrays
    ['tags', 'genres', 'instruments'].forEach(field => {
      if (newSoundData[field] !== undefined) {
        updatedSound[field] = newSoundData[field];
      }
    });

    // Handle file upload
    if (newSoundData.file) {
      const uniqueFolder = uuidv4();
      const uniqueFileName = `${uniqueFolder}/${newSoundData.file.name}`;
      const fileNameWithoutExtension = newSoundData.file.name.replace(/\.[^/.]+$/, "");
      const audioPreviewFileName = `${uniqueFolder}/${fileNameWithoutExtension}_preview.mp3`;

      const uploadResult = await uploadFileToServer(newSoundData.file, uniqueFileName, audioPreviewFileName);
      updatedSound.file_name = uniqueFileName;
      updatedSound.bucket_name = BACKBLAZE_BUCKET_NAME;
      updatedSound.audio_preview = uploadResult.preview_file_url;
      updatedSound.file_size = newSoundData.file.size.toString();
    }

    // Handle image upload
    if (newSoundData.image) {
      const uniqueFolder = uuidv4();
      const imageFileName = `${uniqueFolder}/${newSoundData.image.name}`;
      const uploadResult = await uploadFileToServer(null, null, null, newSoundData.image, imageFileName);
      updatedSound.image = uploadResult.image_file_url;
    }

    updatedSound.file = "";
    if (updatedSound.key != null && updatedSound.key.trim() === "") {
      updatedSound.key = undefined;
    }
    
    const response = await 
      axios.put(API_ENDPOINTS.GET_SOUND(soundId), updatedSound)
    
    handleSuccess('Sound updated successfully');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to update sound');
  }
};

export const fetchSounds = async (page, pageSize, filters = {}) => {
  try {
    // Default status to "active" if not present in filters
    if (!filters.status) {
      filters.status = "active";
    }

    // Remove status if it is set to "all"
    if (filters.status === "all") {
      delete filters.status;
    }

    const response = await 
      axios.get(API_ENDPOINTS.SOUNDS, {
        params: {
          page_number: page,
          page_size: pageSize,
          application: APPLICATION_ID,
          ignore_woocommerce_sync: true,
          ...filters,
        }
      })
    
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch sounds');
    return { items: [], total: 0 };
  }
};

export const fetchFeaturedSounds = async (page, pageSize) => {
  try {
    const response = await
      axios.get(API_ENDPOINTS.SOUNDS, {
        params: {
          page_number: 1,
          page_size: 10,
          application: APPLICATION_ID,
          status: "active",
          featured: true,
          ignore_woocommerce_sync: true,
        }
      })
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch featured sounds');
    return { items: [], total: 0 };
  }
};

export const fetchSoundById = async (soundId) => {
  try {
    const response = await axios.get(API_ENDPOINTS.GET_SOUND(soundId))
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch sound details');
    return {};
  }
};

export const deleteSoundById = async (soundId) => {
  try {
    const response = await axios.delete(API_ENDPOINTS.GET_SOUND(soundId))
    handleSuccess('Sound deleted successfully');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to delete sound');
    return {};
  }
};

// Meta Data
export const fetchSoundTypes = async () => {
  try {
    const response = await axios.get(API_ENDPOINTS.SOUND_TYPES)
    // Filter to include types with either "Loop" or "One Shot" in the name
    const filteredTypes = response.data.filter(type => 
      type.name.toLowerCase() == 'loop'
      || type.name.toLowerCase() == 'one shot'
    );
    return filteredTypes;
  } catch (error) {
    handleError(error, 'Failed to fetch sound types');
    return [];
  }
};

export const fetchTags = async () => {
  try {
    const response = await axios.get(API_ENDPOINTS.TAGS)

    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch tags');
    return [];
  }
};

export const fetchGenres = async (sortByLikes = false) => {
  try {
    const response = await axios.get(API_ENDPOINTS.GENRES + (sortByLikes ? "?sort_by_count=true" : ""))
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch genres');
    return [];
  }
};

export const fetchInstruments = async (sortByLikes = false) => {
  try {
    const response = await 
      axios.get(API_ENDPOINTS.INSTRUMENTS  + (sortByLikes ? "?sort_by_count=true" : ""))
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch instruments');
    return [];
  }
};

export const fetchLicenses = async (includeTOS = false ) => {
  try {
    const response = await axios.get(API_ENDPOINTS.LICENSES);
    const licenses = response.data;

    if (!includeTOS) {
      return licenses.filter(license => license.name !== "Terms of Service");
    }

    return licenses;
  } catch (error) {
    handleError(error, 'Failed to fetch licenses');
    return [];
  }
};

export const acceptLicense = async (licenseId) => {
  try {
    const response = await 
      axios.post(API_ENDPOINTS.ACCEPT_LICENSE(licenseId))
    handleSuccess('License accepted successfully');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to accept license');
    return [];
  }
};

export const createTag = async (tag) => {
  try {
    // First, fetch all existing tags
    const existingTags = await fetchTags();
    
    // Check if a tag with the same name already exists (case-insensitive)
    const existingTag = existingTags.find(
      t => t.name.toLowerCase() === tag.name.toLowerCase()
    );
    
    // If tag exists, return the existing one
    if (existingTag) {
      return existingTag;
    }

    // If tag doesn't exist, create a new one
    const response = await 
      axios.post(API_ENDPOINTS.TAGS, tag)
    handleSuccess('Tag created successfully');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to create tag');
    return null;
  }
};

export const createGenre = async (genre) => {
  try {
    // First, fetch all existing genres
    const existingGenres = await fetchGenres();
    
    // Check if a genre with the same name already exists (case-insensitive)
    const existingGenre = existingGenres.find(
      g => g.name.toLowerCase() === genre.name.toLowerCase()
    );
    
    // If genre exists, return the existing one
    if (existingGenre) {
      return existingGenre;
    }

    // If genre doesn't exist, create a new one
    const response = await 
      axios.post(API_ENDPOINTS.GENRES, genre)
    handleSuccess('Genre created successfully');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to create genre');
    return null;
  }
};

export const createInstrument = async (instrument) => {
  try {
    // First, fetch all existing instruments
    const existingInstruments = await fetchInstruments();
    
    // Check if an instrument with the same name already exists (case-insensitive)
    const existingInstrument = existingInstruments.find(
      i => i.name.toLowerCase() === instrument.name.toLowerCase()
    );
    
    // If instrument exists, return the existing one
    if (existingInstrument) {
      return existingInstrument;
    }

    // If instrument doesn't exist, create a new one
    const response = await 
      axios.post(API_ENDPOINTS.INSTRUMENTS, instrument)
    handleSuccess('Instrument created successfully');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to create instrument');
    return null;
  }
};

// Liking
export const likeSound = async (soundId) => {
  try {
    await 
      axios.post(API_ENDPOINTS.LIKE_SOUND(soundId))
    handleSuccess('Sound liked successfully');
    return true;
  } catch (error) {
    handleError(error, 'Failed to like sound');
    return false;
  }
};

export const unlikeSound = async (soundId) => {
  try {
    await 
      axios.delete(API_ENDPOINTS.UNLIKE_SOUND(soundId))
    handleSuccess('Sound unliked successfully');
    return true;
  } catch (error) {
    handleError(error, 'Failed to unlike sound');
    return false;
  }
};

// Purchases
export const purchaseSound = async (soundId) => {
  try {
    await 
      axios.post(API_ENDPOINTS.PURCHASE_SOUND(soundId))

    handleSuccess('Sound purchased successfully');
    return true;
  } catch (error) {
    handleError(error, 'Failed to purchase sound');
    return false;
  }
};

// Reports
export const reportSound = async (soundId, reason) => {
  try {
    await 
      axios.post(API_ENDPOINTS.REPORT_SOUND(soundId), { reason })

    handleSuccess('Sound reported successfully');
    return true;
  } catch (error) {
    handleError(error, 'Failed to report sound');
    return false;
  }
};

// UPLOAD SOUND
export const uploadFileToServer = async (file, uniqueFileName, audioPreviewFileName, imageFile = null, imageFileName = null, avatarImageFile = null, avatarImageFileName = null, abortSignal = null) => {
  const formData = new FormData();
  
  if (file) {
    // Upload original file
    formData.append('file', file);
    formData.append('file_name', uniqueFileName);
    
    // Convert and upload preview if it's a WAV file
    if (file.type === 'audio/wav' || file.type === 'audio/x-wav') {
      try {
        const mp3Preview = await convertWavToMp3(file);
        formData.append('audio_preview_file', mp3Preview);
        formData.append('audio_preview_file_name', audioPreviewFileName);
      } catch (error) {
        console.error('Failed to convert WAV to MP3:', error);
        throw new Error('Failed to create audio preview');
      }
    }
  }
  
  if (imageFile && imageFileName) {
    formData.append('image_file', imageFile);
    formData.append('image_file_name', imageFileName);
  }

  if (avatarImageFile && avatarImageFileName) {
    formData.append('avatar_image', avatarImageFile);
    formData.append('avatar_image_file_name', avatarImageFileName);
  }
  
  try {
    //ORIGINAL ENDPOINT
    //const response = await axios.post(`${API_ENDPOINTS.UPLOAD_FILE}`, formData, {
    const response = await axios.post(`https://fileupload.soundbank.us/backblazeupload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 0,
      signal: abortSignal // Add the abort signal here
    });
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      const cancelError = new Error('Upload cancelled');
      cancelError.isCancelled = true;
      throw cancelError;
    }
    handleError(error, 'Failed to upload file');
  }
};

// Downloads
export const getDownloadURL = async (soundId) => {
  try {
    const response = await 
      axios.get(API_ENDPOINTS.GET_SOUND_DOWNLOAD_URL(soundId))

    handleSuccess('Download link generated successfully');
    return response.data.downloadUrl;
  } catch (error) {
    handleError(error, 'Failed to generate download link');
  }
};

//USER
// update user
export const updateUser = async (userId, newUserData) => {
  try {
    const originalUser = await fetchUserById(userId);
    const updatedUser = originalUser ;

    // Update basic fields
    const fields = ['username', 'email', 'avatar', 'verified', 'whitelisted', 'featured', 'instagram', 'twitter', 'website', 'bio', 'is_soundbank_user'];
    fields.forEach(field => {
      if (newUserData[field] !== undefined) {
        updatedUser[field] = newUserData[field];
      }
    });

    // Handle image upload
    if (newUserData.avatarFile) {
      const avatarFileName = `${userId}/${newUserData.avatarFile.name}`;
      const uploadResult = await uploadFileToServer(null, null, null, null, null, newUserData.avatarFile, avatarFileName);
      updatedUser.avatar = uploadResult.avatar_image_file_url;
    }

    const response = await 
      axios.put(API_ENDPOINTS.GET_USER(userId), originalUser)

    
    handleSuccess('User updated successfully');
    return response.data;
  } catch (error) {
    // handleError(error, 'Failed to update user');
    throw error;
  }
};

export const deleteUser = async (userId) => {
  try {
    const response = await 
      axios.delete(`${API_ENDPOINTS.USERS}/${userId}`)

    handleSuccess('User deleted successfully');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to delete user');
    return null;
  }
};

// Fetch users with pagination and filters
export const fetchUsers = async (page, pageSize, filters = {}) => {
  try {
      // Remove any undefined or empty string filters
      const cleanFilters = Object.fromEntries(
          Object.entries(filters).filter(([_, value]) => 
              value !== undefined && value !== ''
          )
      );

      const response = await 
          axios.get(API_ENDPOINTS.USERS, {
              params: {
                  page_number: page,
                  page_size: pageSize,
                  is_soundbank_user: true,
                  ...cleanFilters,
              }
          })
  
      return response.data;
  } catch (error) {
      handleError(error, 'Failed to fetch users');
      return { items: [], total: 0 };
  }
};

//Audio Apis
export const analyzeAudio = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axios.post('https://audioapis.soundware.io/analyze_audio', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    handleSuccess('Audio analysis completed successfully');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to analyze audio file');
  }
};

export const generateAiImage = async () => {
  try {
      // Get a random artistic photo from Picsum
      const randomSeed = Math.floor(Math.random() * 1000);
      const response = await fetch(`https://picsum.photos/seed/${randomSeed}/800/800`);
      
      if (!response.ok) {
          throw new Error('Failed to fetch image');
      }

      return await response.blob();
  } catch (error) {
      console.error('Error generating image:', error);
      throw error;
  }
};

// Export the initialized toast function
export const getToast = () => {
  return globalShowToast;
};

// Initialize toast with default values if needed
export const ensureToastInitialized = () => {
  if (globalShowToast.toString().includes('Toast function not initialized')) {
    console.warn('Toast was not initialized, using default implementation');
    initializeToast((message, type) => {
      if (type === 'error') {
        console.error(message);
      } else {
        console.log(message);
      }
    });
  }
};

// Add these functions to your APIManager.js file

// Tags
export const updateTag = async (tagId, tagData) => {
  try {
    const response = await 
      axios.put(`${API_ENDPOINTS.TAGS}/${tagId}`, tagData)
   
    handleSuccess('Tag updated successfully');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to update tag');
    return null;
  }
};

export const deleteTag = async (tagId) => {
  try {
    const response = await 
      axios.delete(`${API_ENDPOINTS.TAGS}/${tagId}`)

    handleSuccess('Tag deleted successfully');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to delete tag');
    return null;
  }
};

// Instruments
export const updateInstrument = async (instrumentId, instrumentData) => {
  try {
    const response = await 
      axios.put(`${API_ENDPOINTS.INSTRUMENTS}/${instrumentId}`, instrumentData)

    handleSuccess('Instrument updated successfully');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to update instrument');
    return null;
  }
};

export const deleteInstrument = async (instrumentId) => {
  try {
    const response = await 
      axios.delete(`${API_ENDPOINTS.INSTRUMENTS}/${instrumentId}`)
    handleSuccess('Instrument deleted successfully');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to delete instrument');
    return null;
  }
};

// Genres
export const updateGenre = async (genreId, genreData) => {
  try {
    const response = await 
      axios.put(`${API_ENDPOINTS.GENRES}/${genreId}`, genreData)
    handleSuccess('Genre updated successfully');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to update genre');
    return null;
  }
};

export const deleteGenre = async (genreId) => {
  try {
    const response = await 
      axios.delete(`${API_ENDPOINTS.GENRES}/${genreId}`)
    
    handleSuccess('Genre deleted successfully');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to delete genre');
    return null;
  }
};

// SoundPacks
// soundpack operations
export const createSoundpack = async (soundpackData) => {
  try {
    const response = await 
      axios.post(API_ENDPOINTS.SOUNDPACKS, soundpackData)
    
    handleSuccess('Soundpack created successfully');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to create soundpack');
    return null;
  }
};

export const fetchSoundpacks = async (filters = {}) => {
  try {
    // Remove any undefined or empty string filters
    const cleanFilters = Object.fromEntries(
      Object.entries(filters).filter(([_, value]) => 
        value !== undefined && value !== ''
      )
    );

    const response = await 
      axios.get(API_ENDPOINTS.SOUNDPACKS, {
        params: {
          ...cleanFilters,
        }
      })
    
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch soundpacks');
    return { items: [], total: 0 };
  }
};

export const updateSoundpack = async (soundpackId, soundpackData) => {
  try {
    const response = await 
      axios.put(API_ENDPOINTS.GET_SOUNDPACK(soundpackId), soundpackData)

    handleSuccess('Soundpack updated successfully');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to update soundpack');
    return null;
  }
};

export const fetchSoundpackById = async (soundpackId) => {
  try {
    const response = await
      axios.get(API_ENDPOINTS.GET_SOUNDPACK(soundpackId))
    
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to fetch soundpack details');
    return {};
  }
};

export const deleteSoundpackById = async (soundpackId) => {
  try {
    const response = await axios.delete(API_ENDPOINTS.GET_SOUNDPACK(soundpackId))
    handleSuccess('Soundpack deleted successfully');
    return response.data;
  } catch (error) {
    handleError(error, 'Failed to delete soundpack');
    return {};
  }
};

// Add this to ensure toast is always available
ensureToastInitialized();

export default {
  initializeToast,
  getToast,
  getDownloadURL,
  analyzeAudio,
  // ... you can list all other exports here if needed
};