import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { JUCESend, JUCEReceive, isJUCE } from '../../context/JUCE';
import { getDownloadURL, likeSound, unlikeSound, purchaseSound, fetchSoundById } from '../../api/APIManager';
import { DEFAULT_SOUND_IMAGE } from '../../api/APIConfig';
import SpinnerFull from '../SpinnerFull';
import Spinner from '../Spinner';
import { SoundCardDialogs } from '../SoundBrowser/SoundCardDialogs';
import { Analytics } from '../../api/Analytics/AnalyticsService';
import ActionButton from '../ActionButton';
import {
  PlayIcon,
  PauseIcon,
  LikeIcon,
  MoreIcon,
  DownloadIcon,
  DragIcon,
  CheckIcon,
  TokenBadge
} from '../../assets/Icons';

const MOBILE_PLAYER_HEIGHT = isJUCE() ? 150 : 88;  // Height for mobile view
const DESKTOP_PLAYER_HEIGHT = isJUCE() ? 150 : 88; // Standard height for audio player

const VolumeIcon = ({ volume }) => {
  if (volume === 0) {
    return (
      <svg className="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
        <path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z"/>
      </svg>
    );
  } else if (volume < 50) {
    return (
      <svg className="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
        <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02z"/>
      </svg>
    );
  } else {
    return (
      <svg className="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
        <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z"/>
      </svg>
    );
  }
};

const VolumeControl = ({ volume, onChange, isMobile }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [previousVolume, setPreviousVolume] = useState(volume);
  const controlRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (controlRef.current && !controlRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleVolumeChange = (e) => {
    const newVolume = parseInt(e.target.value);
    onChange(newVolume);
  };

  const toggleMute = () => {
    if (volume > 0) {
      setPreviousVolume(volume);
      onChange(0);
    } else {
      onChange(previousVolume);
    }
  };

  const renderVolumeSlider = () => (
    <div className={`flex items-center md:pr-4 space-x-2 ${isMobile ? 'py-3 px-4' : ''}`}>
      <input
        type="range"
        min="0"
        max="100"
        value={volume}
        onChange={handleVolumeChange}
        onMouseDown={() => setIsDragging(true)}
        onMouseUp={() => setIsDragging(false)}
        onTouchStart={() => setIsDragging(true)}
        onTouchEnd={() => setIsDragging(false)}
        className="w-24 h-1.5 bg-white/20 rounded-lg appearance-none cursor-pointer
          [&::-webkit-slider-thumb]:appearance-none
          [&::-webkit-slider-thumb]:w-4
          [&::-webkit-slider-thumb]:h-4
          [&::-webkit-slider-thumb]:rounded-full
          [&::-webkit-slider-thumb]:bg-text-primary
          [&::-webkit-slider-thumb]:shadow-md
          [&::-webkit-slider-thumb]:hover:scale-110
          [&::-webkit-slider-thumb]:transition-all
          [&::-moz-range-thumb]:w-4
          [&::-moz-range-thumb]:h-4
          [&::-moz-range-thumb]:rounded-full
          [&::-moz-range-thumb]:bg-text-primary
          [&::-moz-range-thumb]:border-none
          [&::-moz-range-thumb]:shadow-md
          [&::-moz-range-thumb]:hover:scale-110
          [&::-moz-range-thumb]:transition-all"
      />
      {isMobile && (
        <span className="text-xs text-text-secondary min-w-[2.5rem]">
          {volume}%
        </span>
      )}
    </div>
  );

  if (isMobile) {
    return (
      <div ref={controlRef} className="relative">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="p-2 hover:bg-white/10 rounded-full transition-colors"
        >
          <VolumeIcon volume={volume} />
        </button>
        {isOpen && (
          <div className="absolute bottom-full right-0 mb-2 bg-bg-secondary/95 backdrop-blur-lg rounded-lg shadow-lg py-2 min-w-[160px] border border-white/10">
            {renderVolumeSlider()}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="flex items-center space-x-2">
      <button
        onClick={toggleMute}
        className="p-2 hover:bg-white/10 rounded-full transition-colors"
      >
        <VolumeIcon volume={volume} />
      </button>
      {renderVolumeSlider()}
    </div>
  );
};

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(
    () => window.matchMedia(query).matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);
    
    const handler = (event) => {
      setMatches(event.matches);
    };

    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', handler);
    } else {
      mediaQuery.addListener(handler);
    }

    setMatches(mediaQuery.matches);

    return () => {
      if (mediaQuery.removeEventListener) {
        mediaQuery.removeEventListener('change', handler);
      } else {
        mediaQuery.removeListener(handler);
      }
    };
  }, [query]);

  return matches;
};

const PrevIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
    <path d="M6 6h2v12H6zm3.5 6l8.5 6V6z"/>
  </svg>
);

const NextIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
    <path d="M16 6h2v12h-2zm-3.5 6L4 18V6z"/>
  </svg>
);

const AudioPlayer = ({ sound: initialSound, isPlaying, onPlayPause, onEnded, onNext, onPrevious }) => {
  const [sound, setSound] = useState(initialSound);
  const [isLicenseModalOpen, setIsLicenseModalOpen] = useState(false);
  const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
  const [continueToPurchase, setContinueToPurchase] = useState(false);
  const [continueToDownload, setContinueToDownload] = useState(false);
  const waveformRef = useRef(null);
  const waveSurferRef = useRef(null);
  const [isWaveSurferReady, setIsWaveSurferReady] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [volume, setVolume] = useState(100);
  const [isLiked, setIsLiked] = useState(false);
  const [isPurchasing, setIsPurchasing] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showLicenseModal, setShowLicenseModal] = useState(false);
  const isMobile = useMediaQuery('(max-width: 640px)');
  const lastJUCEPosition = useRef(0);
  const buttonClass = "h-8 flex flex-col items-center justify-center text-white rounded transition-all duration-200";

  useEffect(() => {
    setSound(initialSound);
  }, [initialSound]);

  useEffect(() => {
    if (sound) {
      setIsLiked(sound.is_liked || false);
    }
  }, [sound]);

  const handlePlayPause = async () => {
    if (onPlayPause) {
      onPlayPause();
    }
    Analytics.trackSoundPlay(sound);
  };

  const handleLicenseSuccess = async (updatedSound) => {
    setSound(updatedSound);
    if (continueToPurchase) {
      processPurchase();
      setContinueToPurchase(false);
    }
    if (continueToDownload) {
      handleDownload();
      setContinueToDownload(false);
    }
    Analytics.trackLicenseAccept(sound, sound.license);
  };

  const handleLike = async () => {
    if (!sound) return;
    
    const newLikedState = !isLiked;
    setIsLiked(newLikedState);
    
    try {
      if (newLikedState) {
        await likeSound(sound.id);
        sound.is_liked = true;
      } else {
        await unlikeSound(sound.id);
        sound.is_liked = false;
      }
      Analytics.trackSoundLike(sound, newLikedState);
    } catch (error) {
      setIsLiked(!newLikedState);
      setSound(prevSound => ({
        ...prevSound,
        is_liked: !newLikedState
      }));
      console.error('Failed to update like status:', error);
    }
  };

  const processPurchase = async () => {
    setIsPurchasing(true);
    try {
      await purchaseSound(sound.id);
      const updatedSound = await fetchSoundById(sound.id);
      setSound(updatedSound);
      Analytics.trackSoundPurchase(sound);
      if (continueToDownload) {
        handleDownload();
      }
    } catch (error) {
      console.error('Failed to purchase sound:', error);
    } finally {
      setIsPurchasing(false);
    }
  };

  const handlePurchase = async (e) => {
    if (e) e.stopPropagation();
    if (!sound || sound.is_purchased) return;
    
    if (!sound.has_accepted_required_license && sound.license) {
      setIsLicenseModalOpen(true);
      setContinueToPurchase(true);
      return;
    }
    processPurchase();
  };

  const downloadFile = async (url, filename = 'sound.wav') => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobURL = URL.createObjectURL(blob);
      
      const link = document.createElement('a');
      link.href = blobURL;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      
      document.body.removeChild(link);
      URL.revokeObjectURL(blobURL);
    } catch (error) {
      console.error('Error downloading file:', error);
      throw error;
    }
  };

  const handleDownload = async (e) => {
    if (e) e.stopPropagation();
    if (!sound || !sound.is_purchased) return;
    
    if (!sound.has_accepted_required_license && sound.license) {
      setIsLicenseModalOpen(true);
      setContinueToDownload(true);
      return;
    }

    setIsDownloading(true);
    try {
      const downloadUrl = await getDownloadURL(sound.id);
      if (downloadUrl) {
        const soundDetails = await fetchSoundById(sound.id);
        const fileName = soundDetails.file_name.substring(soundDetails.file_name.lastIndexOf('/') + 1);
        await downloadFile(downloadUrl, fileName);
        Analytics.trackSoundDownload(sound);
      }
    } catch (error) {
      console.error('Failed to download sound:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    const handleJUCEReceive = ({ eventName, eventData }) => {
      if (eventName === 'dragExportComplete') {
        setIsDragging(false);
      }
      if (eventName === 'audioPlayerPosition') {
        lastJUCEPosition.current = eventData;
        
        if (waveSurferRef.current) {
          waveSurferRef.current.seekTo(eventData);
          const newTime = eventData * duration;
          setCurrentTime(newTime);
        }
      }
      if (eventName === 'audioPlayerDuration') {
        setDuration(eventData);
      }
    };

    JUCEReceive.on('JUCEReceive', handleJUCEReceive);

    let timeUpdateInterval;
    if (isJUCE() && isPlaying) {
      timeUpdateInterval = setInterval(() => {
        setCurrentTime(prevTime => {
          return lastJUCEPosition.current * duration;
        });
      }, 100);
    }

    return () => {
      JUCEReceive.off('JUCEReceive', handleJUCEReceive);
      if (timeUpdateInterval) {
        clearInterval(timeUpdateInterval);
      }
    };
  }, [duration, isPlaying]);

  useEffect(() => {
    if (waveSurferRef.current) {
      try {
        waveSurferRef.current.destroy();
      } catch (error) {
        console.error("Error while destroying WaveSurfer:", error);
      }
    }
  
    const initWaveSurfer = () => {
      if (!sound || !sound.audio_preview) return;
  
      const primaryColorElement = document.createElement('div');
      primaryColorElement.className = 'text-text-primary hidden';
      document.body.appendChild(primaryColorElement);
      const secondaryColorElement = document.createElement('div');
      secondaryColorElement.className = 'text-text-secondary hidden';
      document.body.appendChild(secondaryColorElement);
  
      const primaryColor = getComputedStyle(primaryColorElement).color;
      const secondaryColor = getComputedStyle(secondaryColorElement).color;
  
      document.body.removeChild(primaryColorElement);
      document.body.removeChild(secondaryColorElement);
  
      waveSurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: secondaryColor,
        progressColor: primaryColor,
        height: 64,
        responsive: true,
        barWidth: 2,
        cursorWidth: 1,
        hideScrollbar: true,
        backend: 'MediaElement',
        fillParent: true,
        loop: true,
      });
  
      const urlWithEncodedHash = sound.audio_preview.replace(/#/g, '%23');
      waveSurferRef.current.load(urlWithEncodedHash);
  
      waveSurferRef.current.on('ready', () => {
        const waveSurferDuration = waveSurferRef.current.getDuration();
        setDuration(waveSurferDuration);
        if (isJUCE()) {
          JUCESend('audioPlayerDuration', waveSurferDuration);
        }
        setIsWaveSurferReady(true);
        if (isPlaying && !isJUCE()) waveSurferRef.current.play();
        waveSurferRef.current.setVolume(volume / 100);
        
        if (waveSurferRef.current.backend && waveSurferRef.current.backend.media) {
          waveSurferRef.current.backend.media.loop = true;
        }
      });
  
      waveSurferRef.current.on('finish', () => {
        if (!isJUCE()) {
          waveSurferRef.current.seekTo(0);
          if (isPlaying) {
            waveSurferRef.current.play();
          }
        }
      });
  
      waveSurferRef.current.on('audioprocess', () => {
        if (!isJUCE()) {
          setCurrentTime(waveSurferRef.current.getCurrentTime());
        }
      });
    };
  
    const timer = setTimeout(initWaveSurfer, 100);
  
    return () => {
      clearTimeout(timer);
      if (waveSurferRef.current) {
        try {
          waveSurferRef.current.destroy();
        } catch (error) {
          console.error("Error while destroying WaveSurfer on cleanup:", error);
        }
      }
    };
  }, [sound, onEnded, volume, isPlaying]);

  useEffect(() => {
    if (isWaveSurferReady && !isJUCE() && waveSurferRef.current) {
      if (isPlaying) {
        waveSurferRef.current.play();
      } else {
        waveSurferRef.current.pause();
      }
    }
  }, [isPlaying, isWaveSurferReady]);

  useEffect(() => {
    if (waveSurferRef.current && !isJUCE()) {
      try {
        waveSurferRef.current.setVolume(volume / 100);
      } catch (error) {
        console.error("Error while setting volume:", error);
      }
    }
  }, [volume]);

  const handleSeek = (e) => {
    if (!waveSurferRef.current) return;
    
    const seekPosition = e.nativeEvent.offsetX / e.target.clientWidth;
    
    if (isJUCE()) {
      JUCESend('audioPlayerSeek', seekPosition);
      setCurrentTime(seekPosition * duration);
      lastJUCEPosition.current = seekPosition;
    } else {
      waveSurferRef.current.seekTo(seekPosition);
      setCurrentTime(seekPosition * duration);
    }
  };

  const handleDragStart = async (e) => {
    if (isJUCE() && sound && sound.is_purchased && sound.has_accepted_required_license) {
      e.preventDefault();
      setIsDragging(true);
      try {
        const downloadUrl = await getDownloadURL(sound.id);
        const soundWithUrl = { ...sound, download_url: downloadUrl };
        JUCESend('dragExport', soundWithUrl);
      } catch (error) {
        console.error('Error getting download URL:', error);
        setIsDragging(false);
      }
    }
  };

  if (!sound) {
    return null;
  }

  if (isDragging) {
    return <SpinnerFull message="Processing audio, please wait..." />;
  }

  return (
    <div 
      className="fixed bottom-0 left-0 right-0 z-10 transition-all duration-300 ease-in-out bg-gradient-to-t from-bg-secondary to-bg-secondary/90 backdrop-blur-md border-t border-white/10 shadow-lg"
      style={{ 
        height: `${isMobile ? MOBILE_PLAYER_HEIGHT : DESKTOP_PLAYER_HEIGHT}px`
      }}
    >
      {/* Progress bar */}
      <div className="absolute top-0 left-0 right-0 h-1 bg-white/5 z-50 overflow-hidden">
        <div 
          className="h-full w-full bg-gradient-to-r from-accent-start to-accent-end"
          style={{ 
            transform: `translateX(${(currentTime / duration * 100 - 100) || 0}%)`,
            transition: 'transform 100ms linear'
          }}
        />
      </div>
  
      {/* Main player container */}
      <div className="bg-gradient-to-t from-bg-secondary to-bg-secondary/90 backdrop-blur-md border-t border-white/10 shadow-lg">
        <div className="px-2 md:px-6 py-4">
          {/* Mobile view (375px) */}
          <div className="md:hidden flex items-center justify-between px-4">
            <div className="flex items-center space-x-4">
              <button onClick={onPrevious} className="p-1">
                <PrevIcon />
              </button>
              <button
                onClick={handlePlayPause}
                className="bg-gradient-to-r from-accent-start to-accent-end text-white p-2 rounded-full"
              >
                {isPlaying ? <PauseIcon /> : <PlayIcon />}
              </button>
              <button onClick={onNext} className="p-1">
                <NextIcon />
              </button>
            </div>
  
            <div className="flex items-center space-x-4">
              <div className="w-10 h-10 relative">
                <img 
                  src={sound?.image || DEFAULT_SOUND_IMAGE} 
                  alt={sound?.name} 
                  className="w-10 h-10 object-cover rounded"
                />
              </div>
              <button
                onClick={handleLike}
                className="p-1"
              >
                <LikeIcon filled={isLiked} />
              </button>
              <ActionButton 
                sound={sound}
                isPurchasing={isPurchasing}
                isDownloading={isDownloading}
                onPurchase={handlePurchase}
                onDownload={handleDownload}
                onDragStart={handleDragStart}
                isJUCE={isJUCE()}
                className="w-14"
              />
            </div>

          </div>
  
          {/* Desktop view (728px and above) */}
          <div className="hidden md:flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <button onClick={onPrevious} className="p-1">
                <PrevIcon />
              </button>
              <button
                onClick={handlePlayPause}
                className="bg-gradient-to-r from-accent-start to-accent-end text-white p-2 rounded-full"
              >
                {isPlaying ? <PauseIcon /> : <PlayIcon />}
              </button>
              <button onClick={onNext} className="p-1">
                <NextIcon />
              </button>
            </div>
  
            <div className="flex items-center space-x-4 flex-grow min-w-0">
              <div className="w-14 relative flex-shrink-0">
                <div className="absolute inset-0 bg-black/20 rounded blur-md transform"></div>
                <img 
                  src={sound?.image || DEFAULT_SOUND_IMAGE} 
                  alt={sound?.name} 
                  className="w-14 h-14 object-cover rounded relative z-10"
                />
              </div>
  
              <div className="max-w-24 md:w-32 flex-shrink-0 min-w-0">
                <div className="text-sm font-semibold text-text-primary line-clamp-2 w-full">
                  {sound?.name}
                </div>
                <div className="text-xs text-text-secondary">
                  {sound?.owner?.username}
                </div>
              </div>
  
              <div className="hidden lg:flex flex-grow items-center min-w-0">
                <div 
                  ref={waveformRef} 
                  className="flex-grow cursor-pointer h-16"
                  onClick={handleSeek}
                />
              </div>
            </div>
  
            <div className="flex items-center space-x-2">
              <button
                onClick={handleLike}
                className="p-2 hover:bg-white/10 rounded-full transition-colors"
              >
                <LikeIcon filled={isLiked} />
              </button>
  
              {!isJUCE() && (
                <div className="hidden md:block">
                  <VolumeControl 
                    volume={volume}
                    onChange={(newVolume) => {
                      setVolume(newVolume);
                      if (waveSurferRef.current && !isJUCE()) {
                        waveSurferRef.current.setVolume(newVolume / 100);
                      }
                    }}
                    isMobile={false}
                  />
                </div>
              )}
  
              <ActionButton 
                sound={sound}
                isPurchasing={isPurchasing}
                isDownloading={isDownloading}
                onPurchase={handlePurchase}
                onDownload={handleDownload}
                onDragStart={handleDragStart}
                isJUCE={isJUCE()}
                className="w-14 md:w-20"
              />
            </div>
          </div>
        </div>
      </div>
  
      {/* License Modal */}
      <SoundCardDialogs
        sound={sound}
        isLicenseModalOpen={isLicenseModalOpen}
        setIsLicenseModalOpen={setIsLicenseModalOpen}
        isReportDialogOpen={isReportDialogOpen}
        setIsReportDialogOpen={setIsReportDialogOpen}
        onLicenseSuccess={handleLicenseSuccess}
      />
    </div>
  );
};

export default AudioPlayer;