import React from 'react';
import { CustomDialog } from '../../components/CustomComponents';
import EditableAvatar from './EditableAvatar';
import { DEFAULT_USER_IMAGE } from '../../api/APIConfig';

const EditProfileDialog = ({
  isOpen,
  onClose,
  profileUser,
  editFormData,
  handleEditFormChange,
  handleSaveChanges,
  handleAvatarChange,
  isUploadingAvatar,
  isSaving,
  updateError,
  isAdmin
}) => {
  if (!profileUser) return null;

  return (
    <CustomDialog
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      title="Edit User Profile"
    >
      <div className="space-y-6 relative">
        {isSaving && (
          <div className="absolute inset-0 bg-bg-primary flex flex-col justify-center items-center z-50">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-accent-end mb-4"></div>
            <p className="text-text-primary text-lg">Saving changes...</p>
          </div>
        )}
        
        {updateError && (
          <div className="p-4 bg-red-500/10 border border-red-500/20 rounded-lg text-red-500">
            {typeof updateError === 'string' ? updateError : 'An error occurred while updating the profile'}
          </div>
        )}
    
        <div className="flex justify-center mb-6">
          <EditableAvatar
            currentAvatar={profileUser.avatar || DEFAULT_USER_IMAGE}
            onAvatarChange={handleAvatarChange}
            isEditable={true}
            username={profileUser.username}
            uploading={isUploadingAvatar}
          />
        </div>
    
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-text-secondary mb-2">
              Username
            </label>
            <input
              type="text"
              name="username"
              value={editFormData.username}
              onChange={handleEditFormChange}
              className="w-full px-4 h-12 bg-bg-primary rounded-md border-2 border-white/20 
                       text-text-primary focus:border-accent-end focus:ring focus:ring-accent-end 
                       focus:ring-opacity-50 transition-all duration-300"
            />
          </div>

          {/* Social Media Inputs */}
          <div>
            <label className="block text-sm font-medium text-text-secondary mb-2">
              Instagram
            </label>
            <input
              type="text"
              name="instagram"
              value={editFormData.instagram}
              onChange={handleEditFormChange}
              className="w-full px-4 h-12 bg-bg-primary rounded-md border-2 border-white/20 
                       text-text-primary focus:border-accent-end focus:ring focus:ring-accent-end 
                       focus:ring-opacity-50 transition-all duration-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-text-secondary mb-2">
              X/Twitter
            </label>
            <input
              type="text"
              name="twitter"
              value={editFormData.twitter}
              onChange={handleEditFormChange}
              className="w-full px-4 h-12 bg-bg-primary rounded-md border-2 border-white/20 
                       text-text-primary focus:border-accent-end focus:ring focus:ring-accent-end 
                       focus:ring-opacity-50 transition-all duration-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-text-secondary mb-2">
              TikTok
            </label>
            <input
              type="text"
              name="tiktok"
              value={editFormData.tiktok}
              onChange={handleEditFormChange}
              className="w-full px-4 h-12 bg-bg-primary rounded-md border-2 border-white/20 
                       text-text-primary focus:border-accent-end focus:ring focus:ring-accent-end 
                       focus:ring-opacity-50 transition-all duration-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-text-secondary mb-2">
              Website
            </label>
            <input
              type="text"
              name="website"
              value={editFormData.website}
              onChange={handleEditFormChange}
              className="w-full px-4 h-12 bg-bg-primary rounded-md border-2 border-white/20 
                       text-text-primary focus:border-accent-end focus:ring focus:ring-accent-end 
                       focus:ring-opacity-50 transition-all duration-300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-text-secondary mb-2">
              Bio
            </label>
            <textarea
              name="bio"
              value={editFormData.bio}
              onChange={handleEditFormChange}
              className="w-full px-4 py-2 h-32 bg-bg-primary rounded-md border-2 border-white/20 
                       text-text-primary focus:border-accent-end focus:ring focus:ring-accent-end 
                       focus:ring-opacity-50 transition-all duration-300"
              rows="4"
            />
          </div>

          {/* Admin Controls */}
          {isAdmin && (
            <div className="space-y-4 pt-4 border-t border-white/10">
              <label className="block text-sm font-medium text-text-secondary">
                Admin Controls
              </label>
                            
              <div className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  name="verified"
                  id="verified"
                  checked={editFormData.verified}
                  onChange={handleEditFormChange}
                  className="w-5 h-5 rounded border-2 border-white/20 bg-bg-primary 
                           text-accent-end focus:border-accent-end focus:ring-accent-end"
                />
                <label htmlFor="verified" className="text-sm font-medium text-text-primary">
                  Verified
                </label>
              </div>

              <div className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  name="is_soundbank_user"
                  id="is_soundbank_user"
                  checked={editFormData.is_soundbank_user}
                  onChange={handleEditFormChange}
                  className="w-5 h-5 rounded border-2 border-white/20 bg-bg-primary 
                           text-accent-end focus:border-accent-end focus:ring-accent-end"
                />
                <label htmlFor="is_soundbank_user" className="text-sm font-medium text-text-primary">
                  SoundBank User
                </label>
              </div>
              
              <div className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  name="whitelisted"
                  id="whitelisted"
                  checked={editFormData.whitelisted}
                  onChange={handleEditFormChange}
                  className="w-5 h-5 rounded border-2 border-white/20 bg-bg-primary 
                           text-accent-end focus:border-accent-end focus:ring-accent-end"
                />
                <label htmlFor="whitelisted" className="text-sm font-medium text-text-primary">
                  Whitelisted
                </label>
              </div>

              <div className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  name="featured"
                  id="featured"
                  checked={editFormData.featured}
                  onChange={handleEditFormChange}
                  className="w-5 h-5 rounded border-2 border-white/20 bg-bg-primary 
                           text-accent-end focus:border-accent-end focus:ring-accent-end"
                />
                <label htmlFor="featured" className="text-sm font-medium text-text-primary">
                  Featured
                </label>
              </div>
            </div>
          )}

          <div className="flex space-x-3 pt-4">
            <button
              onClick={handleSaveChanges}
              className="flex-1 h-12 bg-accent-end hover:bg-accent-start text-white 
                       rounded-md transition-colors duration-200"
            >
              Save Changes
            </button>
            <button
              onClick={onClose}
              className="flex-1 h-12 bg-bg-primary hover:bg-white/10 text-text-primary 
                       rounded-md border-2 border-white/20 transition-colors duration-200"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </CustomDialog>
  );
};

export default EditProfileDialog;