// Layout.jsx
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Outlet, Link } from 'react-router-dom';
import Header from './Header';
import Navigation from './Navigation';
import AudioPlayer from './AudioPlayer/AudioPlayer';
import AudioControls from './AudioPlayer/AudioControls';
import { JUCESend, isJUCE } from '../context/JUCE';
import { useAudioNavigation } from '../context/AudioNavigationContext';

// Constants for fixed heights
const HEADER_HEIGHT = 80; // 20rem
const AUDIO_PLAYER_HEIGHT = 88; // Standard height for audio player
const AUDIO_CONTROLS_HEIGHT = 56; // Height for JUCE controls

const Layout = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {
    currentPlayingSound,
    setCurrentPlayingSound,
    isPlaying,
    setIsPlaying,
    navigateToSound
  } = useAudioNavigation();
  const contentRef = useRef(null);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
    if (isPlaying) {
      JUCESend('pauseSound', '');
    } else {
      JUCESend('playSound', currentPlayingSound);
    }
  };

  const handleNext = () => {
    const nextSound = navigateToSound('next');
    if (nextSound) {
      setCurrentPlayingSound(nextSound);
      JUCESend('playSound', nextSound);
      setIsPlaying(true);
    }
  };

  const handlePrevious = () => {
    const previousSound = navigateToSound('previous');
    if (previousSound) {
      setCurrentPlayingSound(previousSound);
      JUCESend('playSound', previousSound);
      setIsPlaying(true);
    }
  };

  // Calculate bottom padding based on whether audio player and JUCE controls are visible
  const bottomPadding = currentPlayingSound
    ? isJUCE()
      ? AUDIO_PLAYER_HEIGHT + AUDIO_CONTROLS_HEIGHT + 6
      : AUDIO_PLAYER_HEIGHT
    : 0;

  return (
    <div className="flex flex-col h-screen bg-bg-primary text-text-primary font-sans">
      <Header className="fixed top-0 left-0 right-0 z-30 h-20" onMenuToggle={() => setIsMenuOpen(!isMenuOpen)} />
      
      <div className="flex flex-1 w-full mt-20">
        {/* Content container with navigation and main content */}
        <div 
          className="absolute inset-0 top-20 flex"
          style={{ 
            bottom: `${bottomPadding}px`
          }}
        >
          {/* Navigation */}
          <Navigation 
            isOpen={isMenuOpen} 
            onClose={() => setIsMenuOpen(false)} 
            className="h-full overflow-y-auto flex-shrink-0" 
          />
          
          {/* Main content */}
          <main className="flex-1 min-w-0">
            <div 
              ref={contentRef}
              className="h-full overflow-y-auto overscroll-contain w-full bg-bg-primary"
            >
              <div className="min-h-full">
                <Outlet context={{ setCurrentPlayingSound, setIsPlaying }} />
              </div>
            </div>
          </main>
        </div>
      </div>

      {/* Audio player and controls */}
      {currentPlayingSound && (
        <div 
          className="fixed left-0 right-0 bottom-0 z-40"
          style={{ 
            height: isJUCE() ? `${AUDIO_PLAYER_HEIGHT + AUDIO_CONTROLS_HEIGHT}px` : `${AUDIO_PLAYER_HEIGHT}px`
          }}
        >
          <AudioPlayer
            sound={currentPlayingSound}
            isPlaying={isPlaying}
            onPlayPause={handlePlayPause}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
          {isJUCE() && (
            <AudioControls 
              className="bg-bg-secondary border-t border-black border-opacity-25"
              style={{ height: `${AUDIO_CONTROLS_HEIGHT}px` }}
            />
          )}
        </div>
      )}
      
      {/* Upload button */}
      <Link
        to="/upload"
        className="fixed right-6 bg-gradient-to-r from-accent-start to-accent-end rounded-full flex items-center justify-center shadow-lg hover:opacity-90 transition-opacity duration-200 sm:hidden z-40 w-14 h-14"
        style={{ bottom: `${bottomPadding + 16}px` }}
        aria-label="Upload Sound"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="text-white"
        >
          <line x1="12" y1="5" x2="12" y2="19"></line>
          <line x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
      </Link>
    </div>
  );
};

export default Layout;