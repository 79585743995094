import React, { useState, useContext } from 'react';
import { DragIcon, DownloadIcon, TokenBadge } from '../assets/Icons';
import { AuthContext } from '../context/AuthContext';
import PricingModal from './PricingSection';
import Spinner from './Spinner';

const ActionButton = ({
  sound,
  isPurchasing = false,
  isDownloading = false,
  onPurchase,
  onDownload,
  onDragStart,
  isJUCE = false,
  isSelectable = false,
  isSelected = false,
  onSelect,
  className = '',
}) => {
  const { user } = useContext(AuthContext);
  const [isPricingOpen, setIsPricingOpen] = useState(false);
  
  // Base button class with consistent width and spacing
  const baseButtonClass = "w-[72px] h-[36px] px-2 font-medium text-sm border border-white/20 rounded-full flex items-center justify-center mx-1";
  
  // Add background styles for non-loading states
  const buttonClass = `${baseButtonClass} ${isPurchasing || isDownloading ? '' : 'bg-black hover:bg-[#2E1F40]'} text-white transition-all duration-200`;

  if (sound.type === undefined) {
    return null;
  }

  const handlePurchaseClick = (e) => {
    e.stopPropagation();
    
    // Check if user has enough credits
    const hasEnoughCredits = user.credits >= sound.cost_in_credits;
    
    if (!hasEnoughCredits && !user.active_subscriptions?.some(sub => 
      sub.toLowerCase().includes('soundbank')
    )) {
      // Show pricing modal if user doesn't have enough credits and no active subscription
      setIsPricingOpen(true);
    } else {
      // Proceed with purchase if they have enough credits
      onPurchase(e);
    }
  };

  if (isSelectable) {
    return (
      <button 
        onClick={(e) => {
          e.stopPropagation();
          onSelect?.(sound);
        }}
        className={`${buttonClass} ${className} ${
          isSelected 
            ? 'bg-accent-end' 
            : 'hover:bg-accent-end/60'
        }`}
      >
        {isSelected ? 'Remove' : 'Add'}
      </button>
    );
  }

  if (isPurchasing) {
    return (
      <button 
        disabled
        className={`${baseButtonClass} ${className}`}
      >
        <Spinner />
      </button>
    );
  }

  if (sound.is_purchased) {
    if (isJUCE) {
      return (
        <div 
          className={`${buttonClass} drag-icon cursor-grab active:cursor-grabbing ${className}`}
          draggable={true}
          onDragStart={onDragStart}
        >
          <DragIcon className="w-5 h-5" />
        </div>
      );
    } else {
      const isDisabled = !sound.has_accepted_required_license;
      return (
        <button 
          onClick={(e) => {
            e.stopPropagation();
            onDownload(e);
          }}
          className={`${isDownloading ? baseButtonClass : buttonClass} ${className} ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={isDisabled || isDownloading}
        >
          {isDownloading ? (
            <Spinner />
          ) : (
            <DownloadIcon className="w-5 h-5" />
          )}
        </button>
      );
    }
  }

  return (
    <>
      <button 
        onClick={handlePurchaseClick}
        className={`${buttonClass} ${className}`}
      >
        {sound.cost_in_credits ? (
          <div className="flex items-center justify-center gap-1">
            <span>{sound.cost_in_credits}</span>
            <TokenBadge className="w-4 h-4" />
          </div>
        ) : (
          'Get'
        )}
      </button>
      
      <PricingModal 
        isOpen={isPricingOpen}
        setIsOpen={setIsPricingOpen}
      />
    </>
  );
};

export default ActionButton;