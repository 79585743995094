import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { CustomDialog } from '../CustomComponents';
import { useAudioNavigation } from '../../context/AudioNavigationContext';
import { JUCESend, isJUCE, JUCEReceive } from '../../context/JUCE';
import { WaveformStartPointFinder } from '../WaveformStartPointFinder';
import { Music, Wand2, GripVertical, Play, Pause, Loader } from 'lucide-react';

// Spinner component for loading states
const SpinnerFull = ({ message }) => (
  <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50">
    <div className="flex flex-col items-center space-y-4">
      <Loader className="w-8 h-8 text-violet-500 animate-spin" />
      <p className="text-white/80">{message}</p>
    </div>
  </div>
);

// Audio Sample component for playing individual samples
const AudioSample = ({
  url,
  label,
  type,
  isPlaying,
  onPlayPause,
  isStreaming = false,
  sound,
  loopStartPoint = -1,
  processingState = null
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const audioRef = useRef(null);
  const mediaSourceRef = useRef(null);
  const sourceBufferRef = useRef(null);

  useEffect(() => {
    const handleJUCEReceive = ({ eventName }) => {
      if (eventName === 'dragExportComplete') {
        setIsDragging(false);
      }
    };

    JUCEReceive.on('JUCEReceive', handleJUCEReceive);
    return () => JUCEReceive.off('JUCEReceive', handleJUCEReceive);
  }, []);

  const handleDragStart = async (e) => {
    if (!isStreaming && isJUCE()) {
      e.preventDefault();
      setIsDragging(true);

      const uniqueId = `drag_${Date.now()}_${Math.random().toString(36).substring(7)}`;
      
      const soundWithUrl = {
        ...sound,
        name: uniqueId,
        file_name: `${uniqueId}.mp3`,
        type: { name: 'Loop' },
        download_url: url,
        loop_start_point: loopStartPoint,
        loop_end_point: -1,
        bpm: sound.bpm,
        key: sound.key,
        tags: sound.tags,
        genres: sound.genres,
        instruments: sound.instruments,
        category: sound.category
      };

      JUCESend('dragExport', soundWithUrl);
    }
  };

  useEffect(() => {
    if (isStreaming && audioRef.current) {
      if (typeof MediaSource !== 'undefined') {
        const mediaSource = new MediaSource();
        mediaSourceRef.current = mediaSource;
        audioRef.current.src = URL.createObjectURL(mediaSource);

        mediaSource.addEventListener('sourceopen', async () => {
          try {
            sourceBufferRef.current = mediaSource.addSourceBuffer('audio/mpeg');
            const response = await fetch(url);
            const reader = response.body.getReader();

            let done = false;
            while (!done) {
              const { value, done: streamDone } = await reader.read();
              if (value) {
                sourceBufferRef.current.appendBuffer(value);
              }
              done = streamDone;
            }
            mediaSource.endOfStream();
          } catch (error) {
            console.error('Error streaming audio:', error);
            if (audioRef.current) {
              audioRef.current.src = url;
            }
          }
        });
      } else {
        audioRef.current.src = url;
      }
    } else if (audioRef.current) {
      audioRef.current.src = url;
    }

    return () => {
      if (mediaSourceRef.current) {
        if (mediaSourceRef.current.readyState === 'open') {
          mediaSourceRef.current.endOfStream();
        }
        mediaSourceRef.current = null;
      }
      if (audioRef.current) {
        audioRef.current.src = '';
      }
      sourceBufferRef.current = null;
    };
  }, [url, isStreaming]);

  useEffect(() => {
    if (!isStreaming || !audioRef.current) return;
    if (isPlaying) {
      audioRef.current.play().catch(err => console.error(err));
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying, isStreaming]);

  if (isDragging) {
    return <SpinnerFull message="Processing audio, please wait..." />;
  }

  return (
    <div className="group relative transition-all duration-200">
      <div className="absolute inset-0 bg-gradient-to-r from-violet-500/10 to-fuchsia-500/10 rounded-xl blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
      <div className="relative overflow-hidden bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl p-4 hover:border-white/20 transition-all">
        <div className="flex items-center justify-between space-x-4">
          <div className="flex items-center space-x-3">
            <div className="w-10 h-10 rounded-full bg-gradient-to-br from-violet-500 to-fuchsia-500 flex items-center justify-center">
              {type === 'original' ? (
                <Music className="w-5 h-5 text-white" />
              ) : (
                <Wand2 className="w-5 h-5 text-white" />
              )}
            </div>
            <div>
              <span className="text-sm font-medium text-white/90">{label}</span>
              {(isStreaming || processingState) && (
                <div className="flex items-center mt-1">
                  <div className="w-1.5 h-1.5 rounded-full bg-emerald-500 animate-pulse mr-2" />
                  <span className="text-xs text-white/60">
                    {processingState ? processingState : 'Generating...'}
                  </span>
                </div>
              )}
            </div>
          </div>
          
          <div className="flex items-center space-x-2">
            {!isStreaming && isJUCE() && (
              <button 
                className="p-2 rounded-lg hover:bg-white/5 transition-colors"
                draggable={true}
                onDragStart={handleDragStart}
              >
                <GripVertical className="w-5 h-5 text-white/60" />
              </button>
            )}
            <button
              onClick={onPlayPause}
              className="flex items-center space-x-2 px-4 py-2 bg-white/10 hover:bg-white/15 rounded-lg transition-colors"
              disabled={processingState !== null}
            >
              {isPlaying ? (
                <Pause className="w-4 h-4 text-white" />
              ) : (
                <Play className="w-4 h-4 text-white" />
              )}
            </button>
          </div>
        </div>
      </div>
      <audio
        ref={audioRef}
        className="hidden"
        onEnded={() => {
          if (isStreaming && isPlaying) {
            onPlayPause();
          }
        }}
      />
    </div>
  );
};

// Main AISampleDialog component
const AISampleDialog = ({ isOpen, onClose, sound }) => {
  const {
    currentPlayingSound,
    setCurrentPlayingSound,
    isPlaying,
    setIsPlaying
  } = useAudioNavigation();

  const [formData, setFormData] = useState({
    prompt: '',
    model: 'chirp-v4',
    styleNegative: '',
    continueAt: 10,
    style: ''
  });

  const [isLoading, setIsLoading] = useState(false);
  const [generatedSamples, setGeneratedSamples] = useState(null);
  const [inProgressSamples, setInProgressSamples] = useState(null);
  const [error, setError] = useState('');
  const [taskId, setTaskId] = useState(null);
  const [pollInterval, setPollInterval] = useState(null);
  const [loopStartPoint, setLoopStartPoint] = useState(-1);
  const [streamingPlayingId, setStreamingPlayingId] = useState(null);
  const [activeUrl, setActiveUrl] = useState(null);
  const [stemProcessingStates, setStemProcessingStates] = useState({});
  const [stemPollIntervals, setStemPollIntervals] = useState({});

  // Handle audio preview URL
  const audioUrl = sound?.audio_preview;
  const filename = audioUrl ? audioUrl.split('/').pop() : '';
  const encodedFilename = encodeURIComponent(filename);
  const baseUrl = audioUrl ? audioUrl.substring(0, audioUrl.lastIndexOf('/') + 1) : '';
  const encodedUrl = audioUrl ? baseUrl + encodedFilename : '';

  const startStemSeparation = async (sample) => {
    try {
      setStemProcessingStates(prev => ({
        ...prev,
        [sample.id]: 'Starting stem separation...'
      }));
  
      const response = await axios.post(
        'https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-50f1cf42-1fdb-4637-8a81-fca3b5953dce/default/Replicate-DEMUCS-StemSeperation',
        {
          audio_url: sample.audio_url,
          stem_type: "drums"
        }
      );
  
      if (response.status === 200) {
        const sampleIndex = generatedSamples?.length + 1 || 1;
        const stemmedSample = {
          ...sample,
          id: `${sample.id}_stemmed_${Date.now()}`,
          audio_url: response.data.no_drums,
          audio_preview: response.data.no_drums,
          name: `Sample ${sampleIndex} (No Drums)`,
          label: `Sample ${sampleIndex} (No Drums)`,
          type: { name: 'Loop' },
          file_name: `sample_${sampleIndex}_no_drums.mp3`,
          download_url: response.data.no_drums,
          loop_start_point: sample.loop_start_point || -1,
          loop_end_point: -1,
          bpm: sample.bpm || 0,
          key: sample.key || '',
          tags: sample.tags || [],
          genres: sample.genres || [],
          instruments: sample.instruments || [],
          category: sample.category || ''
        };
  
        setGeneratedSamples(prev => [...(prev || []), stemmedSample]);
        
        setStemProcessingStates(prev => {
          const newStates = { ...prev };
          delete newStates[sample.id];
          return newStates;
        });
      } else if (response.status === 202) {
        await new Promise(resolve => setTimeout(resolve, 2000));
        return startStemSeparation(sample);
      } else {
        throw new Error(response.data.body?.error || 'Stem separation failed');
      }
    } catch (error) {
      console.error('Failed to start stem separation:', error);
      setStemProcessingStates(prev => ({
        ...prev,
        [sample.id]: 'Stem separation failed'
      }));
    }
  };

  const handlePlayPause = (sample) => {
    if (sample.type === 'streaming') {
      stopGlobalPlayback();
      if (streamingPlayingId === sample.id) {
        setStreamingPlayingId(null);
      } else {
        setStreamingPlayingId(sample.id);
      }
    } else {
      setStreamingPlayingId(null);
      const isSameSample = currentPlayingSound?.audio_preview === sample.url;
      
      const soundToPlay = {
        ...sound,
        audio_preview: sample.url,
        name: sample.id.includes('_stemmed_') ? 
          sample.name || `${sound.name} (No Drums)` : 
          sample.type === 'original' ? sound.name : `${sound.name} (AI Generated)`,
        owner: sound.owner,
        type: { name: 'Loop' },
        file_name: sample.id.includes('_stemmed_') ? 
          `${sample.id.split('_stemmed_')[0]}_no_drums.mp3` : 
          sound.file_name
      };
  
      if (isSameSample) {
        if (isPlaying) {
          setIsPlaying(false);
          if (isJUCE()) {
            JUCESend('pauseSound', currentPlayingSound);
          }
        } else {
          setIsPlaying(true);
          if (isJUCE()) {
            JUCESend('playSound', currentPlayingSound);
          }
        }
      } else {
        setIsPlaying(true);
        setCurrentPlayingSound(soundToPlay);
  
        if (isJUCE()) {
          JUCESend('playSound', soundToPlay);
        }
      }
  
      if (sample.type === 'generated' || sample.id.includes('_stemmed_')) {
        setActiveUrl(sample.url);
      } else {
        setActiveUrl(null);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleDurationLoad = (duration) => {
    setFormData(prev => ({
      ...prev,
      continueAt: Number((duration / 2).toFixed(5))
    }));
  };

  const handleLoopPointChange = (samplePosition) => {
    setLoopStartPoint(samplePosition);
  };

  const pollTaskStatus = async (taskId) => {
    try {
      const response = await axios.post(
        'https://api.acedata.cloud/suno/tasks',
        { id: taskId, action: "retrieve" },
        {
          headers: {
            Authorization: 'Bearer 641812e5c49949ebaad381cf2ac917f6',
            'Content-Type': 'application/json'
          }
        }
      );

      const samples = response.data.response.data;
      const allCompleted = samples.every(sample => sample.state === 'succeeded');
      
      if (allCompleted) {
        clearInterval(pollInterval);
        setPollInterval(null);
        setGeneratedSamples(samples);
        setInProgressSamples(null);
        setIsLoading(false);

        // Start stem separation for each completed sample
        samples.forEach(sample => {
          startStemSeparation(sample);
        });
      } else {
        setInProgressSamples(samples);
      }
    } catch (err) {
      console.error('Error polling task:', err);
      setError('Failed to check generation status');
      setIsLoading(false);
    }
  };

  const handleGenerate = async () => {
    setIsLoading(true);
    setError('');
    setGeneratedSamples(null);
    setInProgressSamples(null);
    setStemProcessingStates({});

    try {
      const uploadResponse = await axios.post(
        'https://api.acedata.cloud/suno/upload',
        { audio_url: encodedUrl },
        {
          headers: {
            Authorization: 'Bearer 641812e5c49949ebaad381cf2ac917f6',
            'Content-Type': 'application/json'
          }
        }
      );
      const { audio_id } = uploadResponse.data.data;

      const generateResponse = await axios.post(
        'https://api.acedata.cloud/suno/audios',
        {
          action: 'extend',
          prompt: formData.prompt,
          model: formData.model,
          custom: true,
          instrumental: true,
          style_negative: formData.styleNegative,
          audio_id: audio_id,
          continue_at: parseInt(formData.continueAt),
          style: formData.style,
          callback_url: 'https://'
        },
        {
          headers: {
            Authorization: 'Bearer 641812e5c49949ebaad381cf2ac917f6',
            'Content-Type': 'application/json'
          }
        }
      );

      const newTaskId = generateResponse.data.task_id;
      setTaskId(newTaskId);

      const interval = setInterval(() => pollTaskStatus(newTaskId), 10000);
      setPollInterval(interval);
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to generate samples');
      setIsLoading(false);
    }
  };

  const stopGlobalPlayback = () => {
    if (isPlaying) {
      setIsPlaying(false);
      if (isJUCE()) {
        JUCESend('pauseSound', currentPlayingSound);
      }
    }
  };

  const isSamplePlaying = (sample) => {
    if (sample.type === 'streaming') {
      return streamingPlayingId === sample.id;
    }
    return currentPlayingSound?.audio_preview === sample.url && isPlaying;
  };

  const allSamples = audioUrl ? [
    {
      id: 'original',
      url: encodedUrl,
      label: 'Original Sample',
      type: 'original',
      loopStartPoint
    },
    ...(inProgressSamples?.map((sample, index) => ({
      id: `streaming-${sample.id}`,
      url: sample.audio_url,
      label: `Sample ${index + 1}`,
      type: 'streaming',
      isStreaming: true,
      loopStartPoint
    })) || []),
    ...(generatedSamples?.map((sample, index) => ({
      id: sample.id,
      url: sample.audio_url,
      label: `Sample ${index + 1}`,
      type: 'generated',
      loopStartPoint,
      processingState: stemProcessingStates[sample.id]
    })) || [])
  ] : [];

  useEffect(() => {
    if (!isOpen) {
      setStreamingPlayingId(null);
      setActiveUrl(null);
      Object.values(stemPollIntervals).forEach(interval => clearInterval(interval));
      setStemPollIntervals({});
    }
    return () => {
      if (pollInterval) {
        clearInterval(pollInterval);
      }
      Object.values(stemPollIntervals).forEach(interval => clearInterval(interval));
    };
  }, [isOpen, pollInterval]);

  useEffect(() => {
    if (!audioUrl) {
      console.warn('No audio preview available for this sound');
      return;
    }

    if (isOpen) {
      const audio = new Audio(encodedUrl);
      audio.addEventListener('loadedmetadata', () => {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        fetch(encodedUrl)
          .then(response => response.arrayBuffer())
          .then(buffer => audioContext.decodeAudioData(buffer))
          .then(audioBuffer => {
            const exactDuration = audioBuffer.length / audioBuffer.sampleRate;
            handleDurationLoad(exactDuration);
          })
          .catch(error => {
            console.error('Error loading audio:', error);
            setError('Failed to load audio file');
          });
      });
    }
  }, [isOpen, encodedUrl, audioUrl]);

  useEffect(() => {
    const handleJUCEReceive = (event) => {
      if (event.eventName === 'audioStartPointChanged') {
        const newPoint = Number(event.data.value);
        setLoopStartPoint(newPoint);
      }
    };

    JUCEReceive.on('JUCEReceive', handleJUCEReceive);
    return () => JUCEReceive.off('JUCEReceive', handleJUCEReceive);
  }, []);

  return (
    <CustomDialog
      isOpen={isOpen}
      onClose={() => {
        if (pollInterval) {
          clearInterval(pollInterval);
        }
        Object.values(stemPollIntervals).forEach(interval => clearInterval(interval));
        onClose();
      }}
      title="Generate AI Sample"
      maxWidth="md:max-w-2xl"
    >
      <div className="space-y-6">
        {!audioUrl ? (
          <div className="px-4 py-3 bg-yellow-500/10 border border-yellow-500/20 rounded-lg">
            <p className="text-sm text-yellow-400">No audio preview available for this sound.</p>
          </div>
        ) : (
          <>
            <div className="grid gap-4">
              <div className="space-y-2">
                <label className="text-sm text-white/60">Style</label>
                <input
                  type="text"
                  name="style"
                  value={formData.style}
                  onChange={handleInputChange}
                  className="w-full px-4 h-12 bg-white/5 border border-white/10 rounded-lg focus:border-violet-500 focus:ring-1 focus:ring-violet-500 transition-all"
                  placeholder="e.g. trap, melodic, 808"
                />
              </div>

              <div className="space-y-2">
                <label className="text-sm text-white/60">Avoid in Generation</label>
                <input
                  type="text"
                  name="styleNegative"
                  value={formData.styleNegative}
                  onChange={handleInputChange}
                  className="w-full px-4 h-12 bg-white/5 border border-white/10 rounded-lg focus:border-violet-500 focus:ring-1 focus:ring-violet-500 transition-all"
                  placeholder="Elements to avoid..."
                />
              </div>

              <div className="space-y-2">
                <label className="text-sm text-white/60">Continue At (seconds)</label>
                <input
                  type="number"
                  step="0.001"
                  name="continueAt"
                  value={formData.continueAt}
                  onChange={handleInputChange}
                  className="w-full px-4 h-12 bg-white/5 border border-white/10 rounded-lg focus:border-violet-500 focus:ring-1 focus:ring-violet-500 transition-all"
                  min="0"
                />
              </div>
            </div>

            <button
              onClick={handleGenerate}
              disabled={isLoading}
              className="w-full h-12 bg-gradient-to-r from-violet-500 to-fuchsia-500 text-white rounded-lg font-medium hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? (
                <div className="flex items-center justify-center space-x-2">
                  <div className="w-5 h-5 border-2 border-white/20 border-t-white rounded-full animate-spin" />
                  <span>Generating...</span>
                </div>
              ) : (
                'Generate Samples'
              )}
            </button>

            {error && (
              <div className="px-4 py-3 bg-red-500/10 border border-red-500/20 rounded-lg">
                <p className="text-sm text-red-400">{error}</p>
              </div>
            )}

            <div className="space-y-3">
              {allSamples.map((sample) => (
                <AudioSample
                  key={sample.id}
                  url={sample.url}
                  label={sample.label}
                  type={sample.type}
                  isStreaming={sample.isStreaming}
                  isPlaying={isSamplePlaying(sample)}
                  onPlayPause={() => handlePlayPause(sample)}
                  sound={sound}
                  loopStartPoint={sample.loopStartPoint}
                  processingState={sample.processingState}
                />
              ))}
            </div>

            {activeUrl && (
              <div className="h-40 bg-white/5 rounded-lg overflow-hidden">
                <WaveformStartPointFinder 
                  audioUrl={activeUrl}
                  onLoopPointChange={handleLoopPointChange}
                />
              </div>
            )}
          </>
        )}
      </div>
    </CustomDialog>
  );
};

export default AISampleDialog;