import React, { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { createSound, fetchSoundTypes, fetchTags, fetchGenres, fetchInstruments, analyzeAudio, createTag, createGenre, createInstrument,createSoundpack, fetchLicenses, fetchSoundpacks } from '../../api/APIManager';
import { useToast } from '../../context/ToastContext';
import BatchUploadTable from './BatchUploadTable';
import { Upload } from 'lucide-react';
import { AuthContext } from '../../context/AuthContext';

const convertSharpToFlat = (key) => {
    const sharpToFlatMap = {
        'C#': 'Db', 'D#': 'Eb', 'F#': 'Gb', 'G#': 'Ab', 'A#': 'Bb'
    };
    const [note, ...modeParts] = key.split(' ');
    const mode = modeParts.join(' ');
    const upperNote = note.toUpperCase();
    for (const [sharp, flat] of Object.entries(sharpToFlatMap)) {
        if (upperNote === sharp) {
            return mode ? `${flat} ${mode}` : flat;
        }
    }
    return key;
};

const generateUniqueId = () => `file-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

const BatchUpload = () => {
    const { user } = useContext(AuthContext);
    const { showToast } = useToast();
    const [files, setFiles] = useState([]);
    const [soundTypes, setSoundTypes] = useState([]);
    const [availableTags, setAvailableTags] = useState([]);
    const [availableGenres, setAvailableGenres] = useState([]);
    const [availableInstruments, setAvailableInstruments] = useState([]);
    const [licenses, setLicenses] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);
    const uploadCancelRef = useRef(false);
    const { setCurrentPlayingSound, setIsPlaying, currentPlayingSound, isPlaying } = useOutletContext();
    const abortControllerRef = useRef(null);
    const [soundpacks, setSoundpacks] = useState([]);

    useEffect(() => {
        const loadData = async () => {
            try {
                const [types, fetchedTags, fetchedGenres, fetchedInstruments, fetchedLicenses, fetchedSoundpacks] = await Promise.all([
                    fetchSoundTypes(),
                    fetchTags(),
                    fetchGenres(),
                    fetchInstruments(),
                    fetchLicenses(),
                    fetchSoundpacks({
                        page_size: 200,
                        owner_id: user.id 
                    }) 
                    
                ]);
                
                setSoundTypes(types);
                setAvailableTags(fetchedTags);
                setAvailableGenres(fetchedGenres);
                setAvailableInstruments(fetchedInstruments);
                setLicenses(fetchedLicenses);
                setSoundpacks(fetchedSoundpacks.items);  // Add this line
            } catch (error) {
                console.error("Error loading initial data:", error);
                showToast("Failed to load initial data. Please try again.", "error");
            } finally {
                setIsLoading(false);
            }
        };
        loadData();
    }, [showToast]);

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.types.includes('Files')) {
            setIsDragging(true);
        }
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX;
        const y = e.clientY;
        
        if (x <= rect.left || x >= rect.right || y <= rect.top || y >= rect.bottom) {
            setIsDragging(false);
        }
    };

    const handleFileDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        let droppedFiles = [];
        if (e.dataTransfer.items) {
            droppedFiles = Array.from(e.dataTransfer.items)
                .filter(item => item.kind === 'file' && item.type.startsWith('audio/'))
                .map(item => item.getAsFile());
        } else {
            droppedFiles = Array.from(e.dataTransfer.files)
                .filter(file => file.type.startsWith('audio/'));
        }

        if (droppedFiles.length === 0) {
            showToast("Please drop WAV audio files only.", "error");
            return;
        }
        await addFiles(droppedFiles);
    };

    const handleFileInput = async (e) => {
        e.preventDefault();
        const selectedFiles = Array.from(e.target.files).filter(file => file.type.startsWith('audio/'));
        e.target.value = '';
        
        if (selectedFiles.length === 0) {
            showToast("Please select WAV audio files only.", "error");
            return;
        }
        await addFiles(selectedFiles);
    };

    const addFiles = async (newFiles) => {
        const filesWithPreviews = newFiles.map(file => ({
            id: generateUniqueId(),
            file,
            name: file.name.split('.').slice(0, -1).join('.'),
            preview: URL.createObjectURL(file),
            description: '',
            tags: [],
            genres: [],
            instruments: [],
            typeId: '',
            bpm: '',
            key: '',
            status: 'active',
            costInCredits: '1',
            license: '',
            isAnalyzed: false,
            isAnalyzing: true,
            uploadStatus: 'pending',
            image: null,
            imagePreview: null
        }));

        setFiles(prevFiles => [...prevFiles, ...filesWithPreviews]);

        // Analyze files sequentially
        for (const fileWithPreview of filesWithPreviews) {
            try {
                const analysis = await analyzeAudio(fileWithPreview.file);
                
                setFiles(prevFiles => prevFiles.map(file => {
                    if (file.id !== fileWithPreview.id) return file;
                    
                    return {
                        ...file,
                        bpm: Math.round(analysis.bpm).toString(),
                        key: convertSharpToFlat(analysis.key),
                        genres: analysis.genres
                            .filter(genreName => genreName.trim() !== '')
                            .map(genreName => ({
                                name: genreName,
                                id: availableGenres.find(g => g.name.toLowerCase() === genreName.toLowerCase())?.id
                            })),
                        instruments: analysis.instruments
                            .filter(instrumentName => instrumentName.trim() !== '')
                            .map(instrumentName => ({
                                name: instrumentName,
                                id: availableInstruments.find(i => i.name.toLowerCase() === instrumentName.toLowerCase())?.id
                            })),
                        tags: analysis.moods.split(', ')
                            .filter(moodName => moodName.trim() !== '')
                            .map(moodName => ({
                                name: moodName,
                                id: availableTags.find(t => t.name.toLowerCase() === moodName.toLowerCase())?.id
                            })),
                        isAnalyzed: true,
                        isAnalyzing: false
                    };
                }));
            } catch (error) {
                console.error(`Error analyzing file ${fileWithPreview.name}:`, error);
                showToast(`Failed to analyze ${fileWithPreview.name}`, "error");
                setFiles(prevFiles => prevFiles.map(file => {
                    if (file.id !== fileWithPreview.id) return file;
                    
                    return {
                        ...file,
                        isAnalyzed: false,
                        isAnalyzing: false,
                        uploadStatus: 'error',
                    };
                }));
            }
        }
    };

    const handleBrowseClick = (e) => {
        e.stopPropagation();
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
   
    const handleUpload = async (file) => {
        if (uploadCancelRef.current) {
            setFiles(prevFiles => prevFiles.map(f =>
                f.id === file.id ? { ...f, uploadStatus: 'cancelled' } : f
            ));
            return { success: false };
        }
    
        if (!file.typeId || !file.license || (!file.image && !file.imagePreview)) {
            showToast(`Please fill in required fields for ${file.name}`, "error");
            setFiles(prevFiles => prevFiles.map(f =>
                f.id === file.id ? { ...f, uploadStatus: 'error' } : f
            ));
            return { success: false };
        }
    
        abortControllerRef.current = new AbortController();
    
        try {
            setFiles(prevFiles => prevFiles.map(f =>
                f.id === file.id ? { ...f, uploadStatus: 'uploading' } : f
            ));
    
            const result = await createSound(file, abortControllerRef.current.signal);
    
            if (uploadCancelRef.current) {
                setFiles(prevFiles => prevFiles.map(f =>
                    f.id === file.id ? { ...f, uploadStatus: 'cancelled' } : f
                ));
                return { success: false };
            }
    
            setFiles(prevFiles => prevFiles.map(f =>
                f.id === file.id ? { ...f, uploadStatus: 'success' } : f
            ));
    
            showToast(`Successfully uploaded ${file.name}`, "success");
            return { success: true };
        } catch (error) {
            console.error(`Error uploading ${file.name}:`, error);
    
            setFiles(prevFiles => prevFiles.map(f =>
                f.id === file.id ? { ...f, uploadStatus: 'error' } : f
            ));
            showToast(`Failed to upload ${file.name}: ${error.message}`, "error");
            return { success: false };
        }
    };

    const handleCancelUpload = () => {
        uploadCancelRef.current = true; // Set the cancel flag
    
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
            abortControllerRef.current = null;
        }
    
        // Only cancel pending or uploading files
        setFiles(prevFiles => prevFiles.map(f => {
            if (f.uploadStatus === 'uploading' || f.uploadStatus === 'pending') {
                return { ...f, uploadStatus: 'cancelled' };
            }
            return f; // Leave successful and error files unchanged
        }));
    
        setIsUploading(false);
    };

    return (
        <div className="flex flex-col h-[calc(100vh-100px)] overflow-hidden">
            <div className="flex-none p-4 sm:p-8 sm:pb-4">
                <h1 className="text-3xl font-bold">Batch Upload Sounds</h1>
            </div>
            <div className="flex-1 min-h-0 px-4 sm:px-8 overflow-hidden flex flex-col gap-6">
                {files.length === 0 ? (
                    <div 
                        onDrop={handleFileDrop}
                        onDragOver={handleDragOver}
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        className={`
                            flex-1
                            relative
                            border-2 border-dashed rounded-lg
                            transition-all duration-300 ease-in-out
                            flex flex-col items-center justify-center
                            ${isDragging 
                                ? 'border-accent-start bg-accent-start/10 scale-[1.02]' 
                                : 'border-gray-300 hover:border-accent-start hover:bg-accent-start/5'
                            }
                        `}
                    >
                        <Upload 
                            className={`w-16 h-16 mb-4 transition-all duration-300 ${
                                isDragging ? 'text-accent-start scale-110' : 'text-gray-400'
                            }`}
                        />
                        <div className="space-y-2 text-center">
                            <p className="text-xl font-medium">
                                {isDragging ? 'Drop your files here' : 'Drag & drop your WAV files'}
                            </p>
                            <button
                                onClick={handleBrowseClick}
                                className="text-sm text-accent-start hover:underline focus:outline-none"
                            >
                                or click here to browse
                            </button>
                        </div>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileInput}
                            multiple
                            accept="audio/wav"
                            className="hidden"
                            style={{ display: 'none' }}
                        />
                    </div>
                ) : (
                    <div className="h-[calc(100vh-250px)]">
<BatchUploadTable
    files={files}
    setFiles={setFiles}
    soundTypes={soundTypes}
    availableTags={availableTags}
    setAvailableTags={setAvailableTags}       // Add these
    availableGenres={availableGenres}
    setAvailableGenres={setAvailableGenres}   // Add these
    availableInstruments={availableInstruments}
    setAvailableInstruments={setAvailableInstruments} // Add these
    soundpacks={soundpacks}
    setSoundpacks={setSoundpacks}             // Add these
    licenses={licenses}
    createSound={handleUpload}
    createTag={createTag}
    createGenre={createGenre}
    createInstrument={createInstrument}
    createSoundpack={createSoundpack}
    setErrorMessage={(msg) => showToast(msg, "error")}
    editMode={false}
    handleCancelUpload={handleCancelUpload}
    uploadCancelRef={uploadCancelRef}
    handleUpload={handleUpload}
    setCurrentPlayingSound={setCurrentPlayingSound}
    setIsPlaying={setIsPlaying}
    currentPlayingSound={currentPlayingSound}
    isPlaying={isPlaying}
/>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BatchUpload;