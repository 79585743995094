import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { fetchUserById, fetchUserMe, followUser, unfollowUser, updateUser, fetchSounds, fetchUsers } from '../../api/APIManager';
import Spinner from '../../components/Spinner';
import { DEFAULT_USER_IMAGE } from '../../api/APIConfig';
import { VerifiedBadge } from '../../assets/Icons';
import EditableAvatar from './EditableAvatar';
import UserListDialog from './UserListDialog';
import SoundBrowser from '../../components/SoundBrowser/SoundBrowser';
import EditProfileDialog from './EditProfileDialog';
import SoundPacksList from '../../components/SoundPackBrowser/SoundPacksList';
import Button from '../../components/Button';

const TabButton = ({ isActive, onClick, children }) => (
  <button
    onClick={onClick}
    className={`px-6 py-3 font-medium transition-all duration-200 border-b-2 ${
      isActive 
        ? 'text-accent-end border-accent-end' 
        : 'text-text-secondary border-transparent hover:text-text-primary hover:border-white/20'
    }`}
  >
    {children}
  </button>
);

const UserProfile = () => {
  const { userId } = useParams();
  const { user } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState('sounds');
  const [profileUser, setProfileUser] = useState(null);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isUploadingAvatar, setIsUploadingAvatar] = useState(false);
  const [totalSounds, setTotalSounds] = useState(0);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isFollowersDialogOpen, setIsFollowersDialogOpen] = useState(false);
  const [isFollowingDialogOpen, setIsFollowingDialogOpen] = useState(false);

  const [editFormData, setEditFormData] = useState({
    username: '',
    email: '',
    verified: false,
    is_soundbank_user: false,
    whitelisted: false,
    featured: false,
    instagram: '',
    twitter: '',
    tiktok: '',
    website: '',
    bio: ''
  });
  const [updateError, setUpdateError] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const isAdmin = user?.admin;
  const isOwnProfile = userId === 'me' || !userId;

  const loadUserData = useCallback(async () => {
    setIsLoadingUser(true);
    setProfileUser(null);
    try {
      let userData;
      if (userId === 'me' || !userId) {
        userData = await fetchUserMe();
      } else {
        userData = await fetchUserById(userId);
      }
      
      if (!userData) {
        throw new Error('Failed to load user data');
      }
      
      setProfileUser(userData);
      setEditFormData({
        username: userData.username,
        email: userData.email,
        verified: userData.verified || false,
        is_soundbank_user: userData.is_soundbank_user || false,
        whitelisted: userData.whitelisted || false,
        featured: userData.featured || false,
        instagram: userData.instagram,
        twitter: userData.twitter,
        tiktok: userData.tiktok,
        website: userData.website,
        bio: userData.bio,
      });

      const soundsResponse = await fetchSounds(1, 2, { owner: userData.id });
      setTotalSounds(soundsResponse.total);
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setIsLoadingUser(false);
    }
  }, [userId, user.id]);

  useEffect(() => {
    loadUserData();
  }, [loadUserData]);

  const handleAvatarChange = async (file) => {
    if (!file) return;
    
    setIsUploadingAvatar(true);
    try {
      const updatedUser = await updateUser(profileUser.id, {
        avatarFile: file
      });
      
      if (updatedUser) {
        setProfileUser(updatedUser);
      }
    } catch (error) {
      console.error('Error updating avatar:', error);
    } finally {
      setIsUploadingAvatar(false);
    }
  };

  const handleFollow = async () => {
    try {
      await followUser(profileUser.id);
      setProfileUser(prev => ({
        ...prev,
        followers_count: prev.followers_count + 1,
        is_followed: true
      }));
    } catch (error) {
      console.error('Error following user:', error);
    }
  };

  const handleUnfollow = async () => {
    try {
      await unfollowUser(profileUser.id);
      setProfileUser(prev => ({
        ...prev,
        followers_count: prev.followers_count - 1,
        is_followed: false
      }));
    } catch (error) {
      console.error('Error unfollowing user:', error);
    }
  };

  const handleEditClick = () => {
    setEditFormData({
      username: profileUser.username,
      email: profileUser.email,
      verified: profileUser.verified || false,
      is_soundbank_user: profileUser.is_soundbank_user || false,
      whitelisted: profileUser.whitelisted || false,
      featured: profileUser.featured || false,
      instagram: profileUser.instagram,
      twitter: profileUser.twitter,
      tiktok: profileUser.tiktok,
      website: profileUser.website,
      bio: profileUser.bio,
    });
    setIsEditDialogOpen(true);
  };

  const handleEditFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSaveChanges = async () => {
    setUpdateError('');
    setIsSaving(true);
    try {
      await updateUser(profileUser.id, editFormData);
      setProfileUser(prevUser => ({
        ...prevUser,
        ...editFormData
      }));
      setIsEditDialogOpen(false);
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Failed to update user profile. Please try again.';
      setUpdateError(errorMessage);
    } finally {
      setIsSaving(false);
    }
  };

  const SoundsTab = () => (
    <div className="bg-bg-primary rounded-lg">
      <SoundBrowser
        additionalFilters={{ owner: profileUser.id, sort_by_likes: true }}
        title="Sounds"
        isExplorePage={false}
      />
    </div>
  );

  const SoundPacksTab = () => (
    <div className="bg-bg-primary rounded-lg">
      <SoundPacksList
        title="Sound Packs"
        filters={{ owner_id: profileUser.id }}
        showCreateButton={isOwnProfile}
      />
    </div>
  );


  if (isLoadingUser) {
    return (
      <div className="flex justify-center items-center h-full">
        <Spinner />
      </div>
    );
  }

  if (!profileUser) {
    return <Navigate to="/404" />;
  }

  return (
    <div className="bg-bg-primary text-text-primary p-4 sm:p-6">
      <div className="max-w-7xl mx-auto">
        {/* Profile Header Section */}
        <div className="bg-bg-secondary rounded-lg p-4 sm:p-6 mb-6">
          {/* Avatar and Basic Info */}
          <div className="flex flex-col items-center sm:items-start sm:flex-row gap-4 sm:gap-6 mb-6">
            {/* Avatar */}
            <div className="flex-shrink-0">
              <EditableAvatar
                currentAvatar={profileUser.avatar || DEFAULT_USER_IMAGE}
                onAvatarChange={handleAvatarChange}
                isEditable={false}
                username={profileUser.username}
                uploading={isUploadingAvatar}
                className="w-24 h-24 sm:w-32 sm:h-32"
              />
            </div>

            {/* User Info */}
            <div className="flex-grow space-y-2 text-center sm:text-left">
              <h1 className="text-2xl sm:text-3xl font-bold flex items-center justify-center sm:justify-start gap-2 flex-wrap">
                {profileUser.username}
                {profileUser.verified && <VerifiedBadge />}
              </h1>

              {/* Stats Row - Moved inside user info for desktop */}
              <div className="hidden sm:flex items-center gap-6">
                {/* Sounds */}
                <div className="flex items-center gap-1">
                  <span className="font-medium text-lg">{totalSounds}</span>
                  <span className="text-text-secondary">Sounds</span>
                </div>

                {/* Followers */}
                <div 
                  className="flex items-center gap-1 cursor-pointer group"
                  onClick={() => setIsFollowersDialogOpen(true)}
                >
                  <span className="font-medium text-lg group-hover:text-accent-end ">{profileUser.followers_count || 0}</span>
                  <span className="text-text-secondary group-hover:text-accent-end transition-colors">Followers</span>
                </div>

                {/* Following */}
                <div 
                  className="flex items-center gap-1 cursor-pointer group"
                  onClick={() => setIsFollowingDialogOpen(true)}
                >
                  <span className="font-medium text-lg group-hover:text-accent-end">{profileUser.following_count || 0}</span>
                  <span className="text-text-secondary group-hover:text-accent-end transition-colors">Following</span>
                </div>
              </div>

              {/* Action Buttons */}
              <div className="flex flex-col sm:flex-row gap-2 mt-4 w-full sm:w-auto">
                {(isAdmin || isOwnProfile) && (
                  <Button
                    onClick={handleEditClick}
                    className=" bg-accent-end hover:bg-accent-start text-white 
                             transition-colors duration-200 w-full sm:w-auto"
                  >
                    Edit Profile
                  </Button>
                )}
                {!isOwnProfile && (
                  <Button
                    onClick={profileUser.is_followed ? handleUnfollow : handleFollow}
                    className={` ${
                      profileUser.is_followed
                        ? 'bg-gray-500 hover:bg-gray-600'
                        : 'bg-accent-end hover:bg-accent-start'
                    } text-white transition-colors duration-200`}
                  >
                    {profileUser.is_followed ? 'Unfollow' : 'Follow'}
                  </Button>
                )}
              </div>
            </div>
          </div>

          {/* Mobile Stats - Only visible on mobile */}
          <div className="grid grid-cols-3 gap-4 mb-6 text-center sm:hidden">
            {/* Sounds */}
            <div className="flex flex-col items-center gap-1">
              <span className="font-medium text-lg">{totalSounds}</span>
              <span className="text-text-secondary text-sm">Sounds</span>
            </div>

            {/* Followers */}
            <div 
              className="flex flex-col items-center gap-1 cursor-pointer group"
              onClick={() => setIsFollowersDialogOpen(true)}
            >
              <span className="font-medium text-lg">{profileUser.followers_count || 0}</span>
              <span className="text-text-secondary group-hover:text-accent-end transition-colors text-sm">Followers</span>
            </div>

            {/* Following */}
            <div 
              className="flex flex-col items-center gap-1 cursor-pointer group"
              onClick={() => setIsFollowingDialogOpen(true)}
            >
              <span className="font-medium text-lg">{profileUser.following_count || 0}</span>
              <span className="text-text-secondary group-hover:text-accent-end transition-colors text-sm">Following</span>
            </div>
          </div>

         {/* Social Links */}
<div className="flex flex-wrap justify-center sm:justify-start gap-4 mb-6">
  {profileUser?.instagram && (
    <a 
      href={`https://instagram.com/${profileUser.instagram.replace('@', '')}`}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center text-text-secondary hover:text-accent-end transition-colors duration-200"
    >
      <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
        <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
      </svg>
      <span className="text-sm">{profileUser.instagram.replace('@', '')}</span>
    </a>
  )}
  
  {profileUser?.twitter && (
    <a 
      href={`https://x.com/${profileUser.twitter.replace('@', '')}`}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center text-text-secondary hover:text-accent-end transition-colors duration-200"
    >
      <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
        <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
      </svg>
      <span className="text-sm">{profileUser.twitter.replace('@', '')}</span>
    </a>
  )}
  
  {profileUser?.tiktok && (
    <a 
      href={`https://tiktok.com/${profileUser.tiktok.replace('@', '')}`}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center text-text-secondary hover:text-accent-end transition-colors duration-200"
    >
      <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
        <path d="M19.59 6.69a4.83 4.83 0 01-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 01-5.2 1.74 2.89 2.89 0 012.31-4.64 2.93 2.93 0 01.88.13V9.4a6.84 6.84 0 00-1-.05A6.33 6.33 0 005 20.1a6.34 6.34 0 0011.14-4.02v-6.95a8.16 8.16 0 004.65 1.49v-3.93a4.84 4.84 0 01-1.2 0z"/>
      </svg>
      <span className="text-sm">{profileUser.tiktok.replace('@', '')}</span>
    </a>
  )}
  
  {profileUser?.website && (
    <a 
      href={profileUser.website.startsWith('http') ? profileUser.website : `https://${profileUser.website}`}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center text-text-secondary hover:text-accent-end transition-colors duration-200"
    >
      <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"/>
      </svg>
      <span className="text-sm">{profileUser.website}</span>
    </a>
  )}
</div>

          {/* Bio - Always at bottom */}
          {profileUser.bio && (
            <p className="text-text-primary text-center sm:text-left">
              {profileUser.bio}
            </p>
          )}
        </div>

        {/* Tabs Navigation */}
        <div className="bg-bg-primary rounded-lg mb-6">
          <div className="border-b border-white/10">
            <div className="flex">
              <TabButton 
                isActive={activeTab === 'sounds'} 
                onClick={() => setActiveTab('sounds')}
              >
                Sounds
              </TabButton>
              <TabButton 
                isActive={activeTab === 'soundpacks'} 
                onClick={() => setActiveTab('soundpacks')}
              >
                Sound Packs
              </TabButton>
            </div>
          </div>
          
          {/* Tab Content */}
          <div className="p-0">
            {activeTab === 'sounds' ? <SoundsTab /> : 
             activeTab === 'soundpacks' ? <SoundPacksTab /> : 
             <div />}
          </div>
        </div>
      </div>
      
      
      {/* Edit Profile Dialog */}
      <EditProfileDialog
  isOpen={isEditDialogOpen}
  onClose={() => {
    setIsEditDialogOpen(false);
    setUpdateError('');
  }}
  profileUser={profileUser}
  editFormData={editFormData}
  handleEditFormChange={handleEditFormChange}
  handleSaveChanges={handleSaveChanges}
  handleAvatarChange={handleAvatarChange}
  isUploadingAvatar={isUploadingAvatar}
  isSaving={isSaving}
  updateError={updateError}
  isAdmin={isAdmin}
/>
      {/* User List Dialogs */}
<UserListDialog
  isOpen={isFollowersDialogOpen}
  onClose={() => setIsFollowersDialogOpen(false)}
  userId={profileUser.id}
  type="followers"
  initialCount={profileUser.followers_count}
/>

<UserListDialog
  isOpen={isFollowingDialogOpen}
  onClose={() => setIsFollowingDialogOpen(false)}
  userId={profileUser.id}
  type="following"
  initialCount={profileUser.following_count}
/>
    </div>
    
  );
};

export default UserProfile;