// export const API_BASE_URL = 'http://localhost:8080'; // Replace with your actual API base URL
// export const APPLICATION_ID = "0192514d-3027-76b4-938a-40fd83b309ee"; // Local
// export const BACKBLAZE_BUCKET_NAME = "tempbucket01";

// const API_BASE_URL = 'https://staging.soundware.io'; // Replace with your actual API base URL
// export const APPLICATION_ID = "01925766-39fd-7546-be8f-50ba53bdb936"; // Staging
// export const BACKBLAZE_BUCKET_NAME = "tempbucket01";

const API_BASE_URL = 'https://api.soundware.io'; // Replace with your actual API base URL
export const APPLICATION_ID = "01925766-618c-7ec6-8616-6c470cd42b04"; // Production
export const BACKBLAZE_BUCKET_NAME = "portalbucket";


const API_VERSION = 'api/v1'; // Replace with your API version
export const API_ENDPOINTS = {
  SIGN_IN: `${API_BASE_URL}/${API_VERSION}/signin`,
  SIGN_UP: `${API_BASE_URL}/${API_VERSION}/signup`,
  SOUNDS: `${API_BASE_URL}/${API_VERSION}/sounds`,
  USERS: `${API_BASE_URL}/${API_VERSION}/users`,
  GET_USER_ME: `${API_BASE_URL}/${API_VERSION}/users/me`,
  UPLOAD_FILE: `${API_BASE_URL}/${API_VERSION}/sounds/upload`,
  SOUND_TYPES: `${API_BASE_URL}/${API_VERSION}/sounds/types`,
  TAGS: `${API_BASE_URL}/${API_VERSION}/tags`,
  INSTRUMENTS: `${API_BASE_URL}/${API_VERSION}/instruments`,
  GENRES: `${API_BASE_URL}/${API_VERSION}/genres`,
  LICENSES: `${API_BASE_URL}/${API_VERSION}/licenses`,
  SOUNDPACKS: `${API_BASE_URL}/${API_VERSION}/soundpacks`,
  GET_USER:  (userId) => `${API_BASE_URL}/${API_VERSION}/users/${userId}`,
  GET_SOUND: (soundId) => `${API_BASE_URL}/${API_VERSION}/sounds/${soundId}`,
  LIKE_SOUND: (soundId) => `${API_BASE_URL}/${API_VERSION}/sounds/${soundId}/like`,
  UNLIKE_SOUND: (soundId) => `${API_BASE_URL}/${API_VERSION}/sounds/${soundId}/unlike`,
  PURCHASE_SOUND: (soundId) => `${API_BASE_URL}/${API_VERSION}/sounds/${soundId}/purchase`,
  GET_SOUND_DOWNLOAD_URL: (soundId) => `${API_BASE_URL}/${API_VERSION}/sounds/${soundId}/download/request?ignore_woocommerce_sync=true`,
  ACCEPT_LICENSE: (licenseId) => `${API_BASE_URL}/${API_VERSION}/licenses/${licenseId}/accept`,
  REPORT_SOUND: (soundId) => `${API_BASE_URL}/${API_VERSION}/sounds/${soundId}/report`,
  GET_SOUNDPACK: (soundpackId) => `${API_BASE_URL}/${API_VERSION}/soundpacks/${soundpackId}`,

};

export const DEFAULT_SOUND_IMAGE = 'https://via.placeholder.com/200?text=No+Image'; 
export const DEFAULT_USER_IMAGE = 'https://store.soundware.io/wp-content/uploads/2025/01/SoundBank-Character-.png'; 

// export const DEFAULT_SOUND_IMAGE = 'https://static.vecteezy.com/system/resources/thumbnails/042/274/186/small_2x/skilled-artist-playing-piano-electronic-keyboard-in-control-room-recording-new-tracks-with-midi-controller-and-mixing-console-young-musician-practicing-on-synthesizer-in-record-studio-camera-a-video.jpg'; 
// export const DEFAULT_USER_IMAGE = 'https://i1.sndcdn.com/avatars-000302284113-fhxnbn-t240x240.jpg'; 



