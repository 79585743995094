import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useGoogleLogin } from '@react-oauth/google';
import { Eye, EyeOff } from 'lucide-react';

const Alert = ({ children }) => (
  <motion.div
    initial={{ opacity: 0, y: -10 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.1 }}
    className="p-4 mb-6 bg-red-500/10 backdrop-blur-sm border border-red-500/20 rounded-lg text-red-500 text-sm"
  >
    {children}
  </motion.div>
);

const formVariants = {
  enter: (direction) => ({
    x: direction > 0 ? 20 : -20,
    opacity: 0
  }),
  center: {
    x: 0,
    opacity: 1
  },
  exit: (direction) => ({
    x: direction < 0 ? 20 : -20,
    opacity: 0
  })
};

export const AuthSection = ({ 
  isLogin, 
  setIsLogin, 
  formData, 
  handleInputChange, 
  handleSubmit, 
  error, 
  isSubmitting, 
  handleGoogleSuccess, 
  handleGoogleError 
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [[page, direction], setPage] = useState([0, 0]);
  const [usernameWarning, setUsernameWarning] = useState("");

  const login = useGoogleLogin({
    onSuccess: handleGoogleSuccess,
    onError: handleGoogleError,
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: 'https://soundbank.us/landing',
  });

  const paginate = (newPage) => {
    const direction = newPage > page ? 1 : -1;
    setPage([newPage, direction]);
    setIsLogin(newPage === 1);
  };

  const validateUsername = (value) => {
    const usernameRegex = /^[a-z0-9]+$/;
    if (!usernameRegex.test(value)) {
      setUsernameWarning("Username can only contain lowercase letters and numbers");
      return false;
    }
    setUsernameWarning("");
    return true;
  };
  
  const handleUsernameChange = (e) => {
    const value = e.target.value.toLowerCase();
    validateUsername(value);
    handleInputChange(e);  // Call the original handleInputChange directly
  };


  return (
    <div className="w-full max-w-md">
      <div className="backdrop-blur-xl bg-bg-secondary/50 rounded-3xl shadow-2xl border border-white/10 overflow-hidden">
        <div className="p-8 pb-0">
          <div className="relative flex h-9 rounded-lg bg-bg-primary/40">
            <motion.div
              className="absolute inset-y-0 my-0.5 rounded-md bg-white/20"
              style={{ width: 'calc(50% - 4px)' }}
              animate={{ x: isLogin ? 'calc(100% + 2px)' : '2px' }}
              transition={{ type: "spring", stiffness: 500, damping: 50 }}
            />
            <button
              onClick={() => paginate(0)}
              className={`relative z-10 flex-1 text-sm font-medium transition-colors ${
                !isLogin ? 'text-text-primary' : 'text-text-secondary'
              }`}
            >
              Create Account
            </button>
            <button
              onClick={() => paginate(1)}
              className={`relative z-10 flex-1 text-sm font-medium transition-colors ${
                isLogin ? 'text-text-primary' : 'text-text-secondary'
              }`}
            >
              Sign In
            </button>
          </div>
          <div className="text-center mt-4 -mb-4 text-sm text-text-secondary">
            {isLogin ? (
              "Use your existing Soundware account to sign in to SoundBank"
            ) : (
              "Create a free account"
            )}
          </div>
        </div>

        <div className="p-8">
          <AnimatePresence mode="wait">
            {error && <Alert>{error}</Alert>}
            {!isLogin && usernameWarning && (
              <Alert type="warning">{usernameWarning}</Alert>
            )}
          </AnimatePresence>

          <AnimatePresence
            initial={false}
            custom={direction}
            mode="wait"
          >
            <motion.div
              key={page}
              custom={direction}
              variants={formVariants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "spring", stiffness: 500, damping: 50 },
                opacity: { duration: 0.2 }
              }}
            >
              <form onSubmit={(e) => {
                e.preventDefault();
                if (!isLogin && !validateUsername(formData.username)) {
                  return;
                }
                handleSubmit(e);
              }} className="space-y-4">
                <div className="space-y-2">
                  <label className="text-sm font-medium text-text-primary">
                    {isLogin ? "Username or Email" : "Username"}
                  </label>
                  <input
                    type="text"
                    name="username"
                    value={formData.username?.toLowerCase()}
                    onChange={isLogin ? handleInputChange : handleUsernameChange}
                    className="w-full p-3 rounded-xl bg-bg-primary/50 text-text-primary border border-white/10 focus:border-accent-start focus:ring-1 focus:ring-accent-start transition-all placeholder:text-text-secondary"
                    required
                  />
                </div>

                {!isLogin && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ type: "spring", stiffness: 500, damping: 50 }}
                    className="space-y-2"
                  >
                    <label className="text-sm font-medium text-text-primary">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder=""
                      className="w-full p-3 rounded-xl bg-bg-primary/50 text-text-primary border border-white/10 focus:border-accent-start focus:ring-1 focus:ring-accent-start transition-all placeholder:text-text-secondary"
                      required
                    />
                  </motion.div>
                )}

                <motion.div className="space-y-4">
                  <div className="space-y-2">
                    <label className="text-sm font-medium text-text-primary">
                      Password
                    </label>
                    <div className="relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        placeholder=""
                        className="w-full p-3 rounded-xl bg-bg-primary/50 text-text-primary border border-white/10 focus:border-accent-start focus:ring-1 focus:ring-accent-start transition-all placeholder:text-text-secondary"
                        required
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute right-3 top-1/2 -translate-y-1/2 text-text-secondary hover:text-text-primary transition-colors"
                      >
                        {showPassword ? (
                          <EyeOff className="w-5 h-5" />
                        ) : (
                          <Eye className="w-5 h-5" />
                        )}
                      </button>
                    </div>
                    {isLogin && (
                      <div className="text-right">
                        <a
                          href="https://store.soundware.io/account/lost-password/"
                          className="text-sm text-accent-start hover:text-accent-end transition-colors"
                        >
                          Forgot password?
                        </a>
                      </div>
                    )}
                  </div>

                  <motion.button
                    type="submit"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    transition={{ duration: 0.1 }}
                    className="w-full bg-gradient-to-r from-accent-start to-accent-end text-text-primary p-3 rounded-full font-medium shadow-lg hover:shadow-accent-start/25 transition-all duration-300"
                    disabled={isSubmitting}
                  >
                    <span className="relative z-10">
                      {isSubmitting
                        ? isLogin
                          ? 'Signing in...'
                          : 'Creating account...'
                        : isLogin
                        ? 'Sign In'
                        : 'Create Free Account'}
                    </span>
                  </motion.button>

                  <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-white/10"></div>
                    </div>
                    <div className="relative flex justify-center text-sm">
                      <span className="px-2 text-text-secondary bg-bg-secondary/50">Or</span>
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={() => login()}
                    className="w-full bg-black hover:bg-gray-900 text-white p-3 rounded-full font-medium shadow-lg transition-all duration-300 flex items-center justify-center gap-2"
                  >
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path fillRule="evenodd" clipRule="evenodd" d="M17.64 9.205c0-.639-.057-1.252-.164-1.841H9v3.481h4.844a4.14 4.14 0 01-1.796 2.716v2.259h2.908c1.702-1.567 2.684-3.875 2.684-6.615z" fill="#4285F4"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M9 18c2.43 0 4.467-.806 5.956-2.18l-2.908-2.259c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711H.957v2.332A8.997 8.997 0 009 18z" fill="#34A853"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M3.964 10.71A5.41 5.41 0 013.682 9c0-.593.102-1.17.282-1.71V4.958H.957A8.996 8.996 0 000 9c0 1.452.348 2.827.957 4.042l3.007-2.332z" fill="#FBBC05"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M9 3.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C13.463.891 11.426 0 9 0A8.997 8.997 0 00.957 4.958L3.964 7.29C4.672 5.163 6.656 3.58 9 3.58z" fill="#EA4335"/>
                    </svg>
                    {'Continue with Google'}
                  </button>
                </motion.div>
              </form>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default AuthSection;