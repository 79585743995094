import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { API_ENDPOINTS } from '../api/APIConfig';
import { acceptLicense, fetchLicenses } from '../api/APIManager';
import { Analytics } from '../api/Analytics/AnalyticsService';
import { TermsOfService } from '../components/TOS';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [authToken, setAuthToken] = useState(localStorage.getItem('access_token'));
  const [wordpressToken, setWordpressToken] = useState(localStorage.getItem('wordpress_token'));
  const [showTerms, setShowTerms] = useState(false);
  const [termsLicense, setTermsLicense] = useState(null);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [isAcceptingTerms, setIsAcceptingTerms] = useState(false);

  const setAuthHeader = useCallback((token) => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  }, []);

  const checkTermsAcceptance = useCallback(async (userData) => {
    if (!userData) return false;
    
    const acceptedLicense = userData.accepted_licenses?.find(
      license => license.name === 'Terms of Service'
    );
    
    if (acceptedLicense) {
      setHasAcceptedTerms(true);
      return true;
    }

    try {
      const licenses = await fetchLicenses(true);
      const tosLicense = licenses.find(license => license.name === 'Terms of Service');
      
      if (!tosLicense) {
        console.error('Terms of Service license not found');
        return false;
      }
      
      setTermsLicense(tosLicense);
      setShowTerms(true);
      setHasAcceptedTerms(false);
      return false;
    } catch (error) {
      console.error('Failed to fetch licenses:', error);
      return false;
    }
  }, []);

  const refreshUserData = useCallback(async () => {
    const userId = localStorage.getItem('user_id');

    try {
      if (!userId) throw new Error('No user ID available');
      const response = await axios.get(`${API_ENDPOINTS.GET_USER_ME}`);
      return response.data;
    } catch (error) {
      console.error('Failed to refresh user data:', error);
      logout();
      throw error;
    }
  }, []);

  const handleAcceptTerms = useCallback(async () => {
    setIsAcceptingTerms(true);
    try {
      const licenses = await fetchLicenses(true);
      const tosLicense = licenses.find(license => license.name === 'Terms of Service');
      await acceptLicense(tosLicense.id);
      const updatedUserData = await refreshUserData();
      setShowTerms(false);
      setTermsLicense(null);
      setHasAcceptedTerms(true);
      setUser(updatedUserData);
    } catch (error) {
      console.error('Failed to accept terms:', error);
    } finally {
      setIsAcceptingTerms(false);
    }
  }, [refreshUserData]);

  const logout = useCallback(() => {
    const currentUserId = user?.soundware_id;
  
    if (currentUserId) {
      Analytics.trackSignOut(currentUserId);
    }
    Analytics.clearUser();

    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('wordpress_token');
    localStorage.removeItem('user_id');
    localStorage.clear();
    setAuthHeader(null);
    setIsAuthenticated(false);
    setUser(null);
    setAuthToken(null);
    setWordpressToken(null);
    setShowTerms(false);
    setTermsLicense(null);
    setHasAcceptedTerms(false);
  }, [setAuthHeader, user]);

  const checkAuth = useCallback(async () => {
    const token = localStorage.getItem('access_token');
    const userId = localStorage.getItem('user_id');
    
    if (!token || !userId) {
      logout();
      setIsLoading(false);
      return;
    }

    try {
      setAuthHeader(token);
      const userData = await refreshUserData();
      setUser(userData);
      setIsAuthenticated(true);
      await checkTermsAcceptance(userData);
    } catch (error) {
      console.error('Authentication check failed:', error);
      logout();
    } finally {
      setIsLoading(false);
    }
  }, [setAuthHeader, logout, refreshUserData, checkTermsAcceptance]);

  const loginAndLoadUser = async (username, password, googletoken) => {
    setIsLoading(true);
    try {
      const loginResponse = await axios.post(API_ENDPOINTS.SIGN_IN + '?is_soundbank_user=true', { 
        username, 
        password, 
        googletoken 
      });
      
      const { soundware_id, tokens, ...userData } = loginResponse.data;
      
      localStorage.setItem('access_token', tokens.access_token);
      localStorage.setItem('refresh_token', tokens.refresh_token);
      localStorage.setItem('wordpress_token', tokens.wordpress_token);
      localStorage.setItem('user_id', soundware_id);
      
      setAuthToken(tokens.access_token);
      setWordpressToken(tokens.wordpress_token);
      setAuthHeader(tokens.access_token);
      
      Analytics.setUser({
        userId: soundware_id,
        userEmail: userData.email,
        username: userData.username
      });
      
      Analytics.trackSignIn(
        googletoken ? 'google' : 'email',
        {
          userId: soundware_id,
          userEmail: userData.email,
          username: userData.username
        }
      );

      setUser(loginResponse.data);
      setIsAuthenticated(true);
      await checkTermsAcceptance(loginResponse.data);
      
      return true;
    } catch (error) {
      console.error('Login failed:', error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const signupAndLoadUser = async (username, password, email) => {
    setIsLoading(true);
    try {
      const signupResponse = await axios.post(API_ENDPOINTS.SIGN_UP + '?is_soundbank_user=true', {
        username,
        password,
        email
      });

      const { soundware_id, tokens } = signupResponse.data;
      
      localStorage.setItem('access_token', tokens.access_token);
      localStorage.setItem('wordpress_token', tokens.wordpress_token);
      localStorage.setItem('refresh_token', tokens.refresh_token);
      localStorage.setItem('user_id', soundware_id);
      
      setAuthToken(tokens.access_token);
      setWordpressToken(tokens.wordpress_token);
      setAuthHeader(tokens.access_token);
      
      const userData = signupResponse.data;
      setUser(userData);
      setIsAuthenticated(true);
      await checkTermsAcceptance(userData);
      
      return true;
    } catch (error) {
      console.error('Signup failed:', error.response?.data);
      throw error.response?.data;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);
  
  return (
    <AuthContext.Provider value={{ 
      isAuthenticated, 
      isLoading, 
      user, 
      authToken,
      wordpressToken,
      hasAcceptedTerms,
      checkAuth, 
      loginAndLoadUser,
      signupAndLoadUser,
      logout, 
      refreshUserData,
      isAcceptingTerms,
      handleAcceptTerms,
    }}>
      {showTerms && termsLicense ? (
        <TermsOfService
          license={termsLicense}
          onAccept={handleAcceptTerms}
        />
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};