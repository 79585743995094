// SoundPacksPage.jsx
import React from 'react';
import SoundPacksList from '../components/SoundPackBrowser/SoundPacksList';

const SoundPacksPage = () => {
  return (
    <SoundPacksList 
      title="Sound Packs"
      filters={{}}  
    />
  );
};

export default SoundPacksPage;